import React from 'react';
import Header from '../../components/Header';
import Form from '../../components/Form';
import FormItem from '../../components/Form/FormItem';
import PetitionTable from '../AStepProject/PetitionTable';
import PetitionDB from '../../units/db/petition-db';

class UAPetitionNumber extends React.Component {

    constructor(props) {
        super(props);

        let fromItems= [];

        fromItems[0] = FormItem.createSelect({
            title: 'Категорія',
            description: 'Оберіть категорію.',
            options: [
                { value: 'ua-heroes', title: 'Герої України' }
            ],
            value: 'ua-heroes'
        });

        fromItems[1] = FormItem.createNumber({
            required: true,
            unique: [],
            title: 'Номер петиції',
            description: 'Додайте номер петиції до списку.',
            value: '',
            maxSize: 15
        });

        this.state = {
            fromItems
        }

        PetitionDB.addUpdatePetitionIds(this.updateUniquePetitionIds);
    }

    componentDidMount() {
        const category = this.state.fromItems[0]?.value;
        PetitionDB.update(category);
    }

    updateUniquePetitionIds = (petitionIds) => {
        const fromItems = structuredClone(this.state.fromItems);
        fromItems[1].unique = Object.keys(petitionIds);
        this.setState({ fromItems });
    }

    clearPetitionNumberValue = () => {
        const fromItems = structuredClone(this.state.fromItems);
        fromItems[1].dirty = false;
        fromItems[1].value = '';
        this.setState({ fromItems });
    }

    handleClickSave = () => {
        const { fromItems } = this.state;
        const category = fromItems[0]?.value;
        const petitionId = fromItems[1]?.value;

        if (!isNaN(+petitionId)) {
            this.clearPetitionNumberValue();
            PetitionDB.save(category, petitionId).then(() => {
                PetitionDB.update(category);
                alert('Було додано петицію за номером: ' + petitionId);
            });
        }
    }

    handleChange = (fromItem) => {

        if (this.state.fromItems[0].id === fromItem.id) {
            PetitionDB.update(fromItem.value);
        }
    }

    render() {

        return <>
            <Header indent={5} title={'Додати номер петиції'} />
            <Form fromItems={this.state.fromItems}
                  okButtonTitle={'Додати'}
                  onClickOk={this.handleClickSave}
                  onChange={this.handleChange} />
            <PetitionTable />
        </>
    }
}

UAPetitionNumber.propTypes = {
}

export default UAPetitionNumber;