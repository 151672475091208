import React from 'react';
import AStepsProjectDB from '../../units/db/asteps-project-db';
import FieldListTransformer from '../../units/transformers/field-form-transformer';
import GroupListTransformer from '../../units/transformers/group-form-transformer';
import AutomationTransformer from '../../units/transformers/automation-transformer';
import Header from '../../components/Header';
import Row from '../../components/Row';
import UserInterfaceEditor from '../../shared/UserInterfaceEditor';
import AutoStepsEditor from '../../shared/AutoStepsEditor';
import {useNavigate, useParams} from "react-router-dom";
import IconButton from "../../components/IconButton";
import Space from "../../components/Space";
import JSONFile from "../../units/file";

class AStepsViewer extends React.Component {

    constructor(props) {
        super(props);
        this.fieldListTransformer = new FieldListTransformer();
        this.groupListTransformer = new GroupListTransformer();
        this.automationTransformer = new AutomationTransformer();

        const fieldListForm = this.fieldListTransformer.transformToForm({});
        const groupListForm = this.groupListTransformer.transformToForm([]);

        this.state = {
            title: '...',
            description: '',
            fieldListForm,
            groupListForm
        }
    }

    componentDidMount() {

        AStepsProjectDB.findProject(this.props.param)
            .then((response) => {
                this.setState({
                    title: response.title,
                    description: response.description,
                })

                return AStepsProjectDB.findAutomation(this.props.param);
            }).then((response) => {
            try {
                const body = JSON.parse(response.autoSteps);
                this.handleUpdateForm(body);
            } catch (e) {}
        }).catch((e) => {
            this.props.navigate('/not-found-project');
        })
    }

    handleUpdateForm = ({ fields, groups }) => {
        const result = {};
        result.fieldListForm = this.fieldListTransformer.transformToForm(fields || {});
        result.groupListForm = this.groupListTransformer.transformToForm(groups || []);
        this.setState(result);
    }

    handleDownload = () => {
        const fileName = prompt('Введіть назву JSON файла');

        if (fileName) {
            const body = {};
            body.groups = this.groupListTransformer.translateToBody(this.state.groupListForm);
            body.fields = this.fieldListTransformer.translateToBody(this.state.fieldListForm);
            JSONFile.write(fileName, body).then(alert, alert);
        }
    }

    render() {

        return <>
            <Header indent={5} title={this.state.title}>
                <Space hSize={'10px'} />
                <IconButton iconName={'MdDownload'}
                            tooltipAlign={'left'}
                            tooltipInfo={'Зберегти на PC'}
                            onClick={this.handleDownload}/>
            </Header>
            <Row>
                <UserInterfaceEditor indent={7}
                                     behaviorAs={'viewer'}
                                     fields={this.state.fieldListForm} />
            </Row>
            <Row>
                <AutoStepsEditor fields={this.state.fieldListForm}
                                 groups={this.state.groupListForm}
                                 editable={false} />
            </Row>
        </>
    }
}

export default () => {
    const param = useParams()?.['*'];
    const navigate = useNavigate();

    return (
        <AStepsViewer param={param} navigate={navigate} />
    )
}