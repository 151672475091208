import React from 'react';
import PropTypes from 'prop-types';
import AppComponent from '../AppComponent';
import './index.css';

class Button extends AppComponent {

    static style = {
        add: 'button-add',
        primitive: 'button-primitive',
        disabled: 'button-disabled'
    }

    constructor(props) {
        super('Button', props);
    }

    handleClick = (event) => {
        if (!this.props.disabled) {
            this.props.onClick?.(this.props.args);
        }
        event.preventDefault();
    }

    updateClassName(className) {

        if (this.props.disabled) {
            className += ' ' + Button.style['disabled'];
        } else {
            const style = this.props.buttonStyle || 'primitive';
            const buttonStyle = Button.style[style] || Button.style['primitive']
            className += ' ' + buttonStyle;
        }

        return className;
    }

    render() {
        return <button className={this.updateClassName('button-content')}
                       style={this.props.style}
                       disabled={this.props.disabled}
                       onClick={this.handleClick}>
            {this.props.name}
        </button>
    }
}

Button.propTypes = {
    name: PropTypes.string.isRequired,
    style: PropTypes.object,
    buttonStyle: PropTypes.string,
    disabled: PropTypes.bool,
    args: PropTypes.object,
    onClick: PropTypes.func
}

Button.defaultProps = {
    buttonStyle: 'primitive'
}

export default Button;