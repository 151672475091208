import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import './index.css';
import Card from "../../../../components/Card";
import Row from "../../../../components/Row";
import Input from "../../../../components/Input";
import IconButton from "../../../../components/IconButton";
import Column from "../../../../components/Column";
import Space from "../../../../components/Space";
import Field from "../index";

class DropDownItem extends React.Component {

    static createFirstItem() {
        return {
            id: uuidv4(),
            value: 'none',
            title: 'Нічого не вибрано'
        }
    }

    static createItem() {
        return {
            id: uuidv4(),
            value: '',
            title: ''
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            visible: true
        }
    }

    handleChangeKey = (value, args) => this.handleUpdate({ value }, args)
    handleChangeTitle = (title, args) => this.handleUpdate({ title }, args)

    handleValidateValue = (errorMessages) => this.handleValidate('dropdown:value', errorMessages)
    handleValidateTitle = (errorMessages) => this.handleValidate('dropdown:title', errorMessages)
    handleValidate = (key, errorMessages) => this.props.onValidate?.(key, errorMessages)

    handleAdd = (args) => {
        const id = args.item.id;

        if (this.hasError) {
            this.props.onDirty();
            return;
        }

        const dropdown = DropDownItem.createItem();

        this.handleChange('field-dropdown', 'add',
            { id, dropdown });
    }

    handleUpdate = (value, args) => {
        const id = args.item.id;
        const dropdown = { ...args.item, ...value };

        this.handleChange?.('field-dropdown', 'update',
            { id, dropdown });
    }

    handleRemove = (args) => {
        const id = args.item.id;

        if (args.item.value === 'none') {
            return;
        }

        this.handleValidateValue('');
        this.handleValidateTitle('');

        this.handleChange('field-dropdown', 'remove',
            { id });
    }

    handleChange = (item, action, result) => {
        this.props.onChange?.(item, action, result);
    }

    handleVisibleDropDownList = () => {
        this.setState({ visible: !this.state.visible });
    }

    get rowStyle() {
        return {
            marginTop: '7px',
            color: this.state.visible ? '#a6d7ff' : '#1d9aff'
        }
    }

    get hasError() {
        return this.props.hasError
            && (!!this.props.uiErrors[this.props.fieldId + ':dropdown:value']
                || !!this.props.uiErrors[this.props.fieldId + ':dropdown:title'])
    }

    render() {
        return <>
            <Row horizontalAlign={'center'}>
                <IconButton style={this.rowStyle}
                            iconName={this.state.visible ? 'MdExpandLess' : 'MdExpandMore'}
                            onClick={this.handleVisibleDropDownList} />
                <div style={{ width: '95%' }}>
                    {this.props.headerElement}
                </div>
            </Row>
            {this.state.visible &&
                <div className={'drop-down-list-container'}>
                    {this.props.items.map((item) =>
                        <Row key={item.id} className={'drop-down-item-container'}>
                            <Column size={this.props.behaviorAs !== 'viewer' ? 5 : 6}>
                                <Input args={{ item }}
                                       dirty={this.props.dirty}
                                       required={true}
                                       disabled={item.value === 'none'}
                                       value={item.value}
                                       label={'Значеня'}
                                       onChange={this.handleChangeKey}
                                       onValidate={this.handleValidateValue} />
                            </Column>
                            <Column size={this.props.behaviorAs !== 'viewer' ? 5 : 6}>
                                <Input args={{ item }}
                                       dirty={this.props.dirty}
                                       required={true}
                                       disabled={item.value === 'none'}
                                       value={item.title}
                                       label={'Назва'}
                                       onChange={this.handleChangeTitle}
                                       onValidate={this.handleValidateTitle} />
                            </Column>
                            {this.props.behaviorAs === 'viewer' &&
                                <Space wSize={'20px'} />
                            }
                            {this.props.behaviorAs !== 'viewer' &&
                                <Column size={2}>
                                    <Space hSize={'7px'} />
                                    <Row horizontalAlign={'center'}>
                                        <IconButton args={{ item }}
                                                    disabled={this.props.dirty && this.hasError}
                                                    iconName={'MdOutlineAdd'}
                                                    buttonStyle={'add'}
                                                    tooltipAlign={'left'}
                                                    tooltipInfo={'Додати опцію'}
                                                    onClick={this.handleAdd} />
                                        <Space wSize={'5px'} />
                                        {item.value !== 'none' &&
                                            <IconButton args={{ item }}
                                                        buttonStyle={'remove'}
                                                        iconName={'MdOutlineRemove'}
                                                        tooltipAlign={'left'}
                                                        tooltipInfo={'Видалити опцію'}
                                                        onClick={this.handleRemove} />
                                        }
                                    </Row>
                                </Column>
                            }
                        </Row>
                    )}
                </div>
                }
            </>
    }
}

DropDownItem.propTypes = {
    fieldId: PropTypes.string,
    hasError: PropTypes.bool,
    uiErrors: PropTypes.object,
    headerElement: PropTypes.element,
    items: PropTypes.array.isRequired,
    behaviorAs: PropTypes.string,
    onChange: PropTypes.func,
    onValidate: PropTypes.func,
    onDirty: PropTypes.func
}

DropDownItem.defaultProps = {
    behaviorAs: 'editor'
}

export default DropDownItem;