import * as firebase from 'firebase/app';
import { getDatabase, ref, set, get, onValue } from 'firebase/database';
import FirebaseOptions from './firebase-options';

export default class AStepsProjectDB {

    static projects = [];

    static #instance = null;

    constructor() {

        if (AStepsProjectDB.#instance) {
            return AStepsProjectDB.#instance;
        }

        AStepsProjectDB.#instance = this;
        return this;
    }

    static getInstance() {
        if (AStepsProjectDB.#instance) {
            return AStepsProjectDB.#instance;
        } else {
            return new AStepsProjectDB();
        }
    }

    async connect() {
        let app = null;
        let apps = firebase.getApps();

        if (apps.length !== 0) {
            app = await firebase.deleteApp(apps[0]).then(() => {
                return firebase.initializeApp(FirebaseOptions.aStepsConfig);
            });
        } else {
            app = firebase.initializeApp(FirebaseOptions.aStepsConfig);
        }

        return getDatabase(app);
    }

    disconnect() {
        let apps = firebase.getApps();

        if (apps.length !== 0) {
            return firebase.deleteApp(apps[0]);
        }
    }

    set handlerUpdateProjects(handler) {

        if (!this._handlerUpdateProjects) {
            this._handlerUpdateProjects = [];
        }

        this._handlerUpdateProjects.push(handler);
    }

    get handlerUpdateProjects() {
        return this._handlerUpdateProjects;
    }

    static addUpdateAStepsProjects(handler) {
        new AStepsProjectDB().handlerUpdateProjects = handler;
    }

    static init() {
        AStepsProjectDB.update();
    }

    static save(projectPath, title, description, autoSteps) {
        return AStepsProjectDB.set((db) => {
            const automationRef = ref(db, 'asteps/automation/' + projectPath);
            const projectRef = ref(db, 'asteps/projects/' + projectPath);
            return set(projectRef, {title, description})
                .then(() => set(automationRef, {autoSteps}))
        }, 10000);
    }

    static async saveAutomation(projectPath, autoSteps) {

        return AStepsProjectDB.set((db) => {
            const automationRef = ref(db, 'asteps/automation/' + projectPath);
            return set(automationRef, { autoSteps });
        }, 5000);
    }

    static async saveProject(projectPath, title, description) {

        return AStepsProjectDB.set((db) => {
            let projectRef = ref(db, 'asteps/projects/' + projectPath);
            return set(projectRef, { title, description });
        }, 5000);
    }

    /*
    * Завантажує petition ids з DB
    * Потім відновлює список
    * */
    static update() {
        AStepsProjectDB.getAll().then((projects) => {
            new AStepsProjectDB().handlerUpdateProjects?.forEach((handler) => handler(projects))
        })
    }

    static findProject(projectPath) {
        return AStepsProjectDB.findOne('projects/' + projectPath);
    }

    static findAutomation(projectPath) {
        return AStepsProjectDB.findOne('automation/' + projectPath);
    }

    static set(callback, timeout) {
        return new Promise(async (resolve, reject) => {
            const timer = setTimeout(() => reject('timeout'), timeout);
            const db = await AStepsProjectDB.getInstance().connect();

            callback(db).then(() => {
                clearTimeout(timer);
                resolve('ok');
            })
                .catch(() => reject('failure'))
                .finally(AStepsProjectDB.getInstance().disconnect);
        });
    }

    static findOne(path) {

        return new Promise(async (resolve, reject) => {
            const timer = setTimeout(() => reject('timeout'), 5000);
            const db = await AStepsProjectDB.getInstance().connect();
            let aStepsRef = ref(db, 'asteps/' + path);

            return get(aStepsRef).then((snapshot) => {
                if (snapshot.exists()) {
                    clearTimeout(timer);
                    const response = snapshot.val();
                    resolve(response);
                } else {
                    reject('not-available');
                }
            }).catch((err) => {
                console.log(err);
                reject('unexpected-error');
            });
        });
    }

    static getAll() {

        return new Promise(async (resolve, reject) => {
            const timer = setTimeout(() => reject('timeout'), 30000);
            const db = await AStepsProjectDB.getInstance().connect();
            const projectsRef = ref(db,'asteps/projects');

            onValue(projectsRef,(snapshot) => {
                clearTimeout(timer);

                AStepsProjectDB.projects = [];
                snapshot.forEach((childSnapshot) => {
                    const projectPath = childSnapshot.key;
                    const childData = childSnapshot.val();
                    const title = childData.title;
                    const description = childData.description;
                    AStepsProjectDB.projects.push({
                        id: projectPath,
                        projectPath,
                        title,
                        description
                    });
                });

                resolve(AStepsProjectDB.projects);
            });
        })
    }

}