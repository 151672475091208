import React from 'react';
import PropTypes from 'prop-types';

class Space extends React.Component {

    render() {
        return (<div style={{
            width: this.props.wSize || this.props.size,
            height: this.props.hSize || this.props.size
        }} />);
    }
}

Space.propTypes = {
    wSize: PropTypes.string,
    hSize: PropTypes.string,
    size: PropTypes.string
}

export default Space;