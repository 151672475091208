import React from 'react';
import Row from '../../../components/Row';
import { ReactComponent as ActiveLogo } from '../../../asserts/active-logo.svg';
import './index.css';
import RequestCommand from '../../../units/command/request-command';
import CommandType from '../../../units/command/command-type';
import Icon from '../../../components/Icon';

class KiwiBrowserLink extends React.Component {

    static URL = 'https://play.google.com/store/apps/details?id=com.kiwibrowser.browser';

    constructor(props) {
        super(props);

        this.state = {
            checkedAddon: false,
            errorReason: null,
            numberOfConnectionAttempts: 0
        }
    }

    handleSuccess = () => {
        this.setState({ checkedAddon: true, errorReason: null });
    }

    handleFailure = (errorReason) => {
        if (!errorReason) {
            this.setState({ checkedAddon: true,  errorReason: 'unexpected-error' });
            return;
        }

        if (this.state.numberOfConnectionAttempts >= 4) {
            this.setState({ checkedAddon: true,  errorReason });
        } else {
            const numberOfConnectionAttempts = this.state.numberOfConnectionAttempts + 1;
            this.setState({ numberOfConnectionAttempts },
                this.handleRefresh);
        }
    }

    handleRefresh = () => {
        RequestCommand.request(CommandType.CHECK_ADDON, {})
            .then(this.handleSuccess)
            .catch(this.handleFailure);
    }

    componentDidMount() {
        this.handleRefresh();
    }

    render() {

        return <a className={'kiwi-browser-link-container'} target='_blank' href={KiwiBrowserLink.URL}>
            <Row className={'kiwi-browser-logo-content active-kiwi-browser'}
                        horizontalAlign={'center'}
                        verticalAlign={'center'}>
                <div style={{ width: '80%', padding: '0 10px 0 10px' }}>
                    <Row horizontalAlign={'center'} verticalAlign={'center'}>
                        <span className={'kiwi-browser-title'}>Kiwi Browser</span>
                    </Row>
                    <Row horizontalAlign={'center'} verticalAlign={'center'}>
                        <Icon className={'mobile-icon-for-kiwi-browser'} name={'MdSecurityUpdate'} />
                    </Row>
                    <Row horizontalAlign={'center'} verticalAlign={'center'}>
                        <span className={'kiwi-browser-description'}>Цей браузер підтримує add-ons</span>
                    </Row>
                </div>
            </Row>
        </a>
    }
}

export default KiwiBrowserLink;