import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

class ListItem extends React.Component {

   handleClick = () => this.props.onClick?.(this.props.id);

    render() {
        return <div className={this.props.active ? 'active-item-container' : 'item-container'}
                    onClick={this.handleClick} >
            <div className={'item-title'}>
                {this.props.title}
            </div>
        </div>
    }
}

ListItem.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    active: PropTypes.bool,
    onClick: PropTypes.func
}

export default ListItem;