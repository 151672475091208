import React from 'react';
import Description from '../../components/Description';
import Column from '../../components/Column';
import Icon from '../../components/Icon';
import Row from '../../components/Row';
import './index.css';

class CheckAddonMessage extends React.Component {

    render() {

        return <Row className={'check-addon-container'} verticalAlign={'center'}>
            <Column size={1} style={{ alignItems: 'center' }}>
                <Icon className={'check-addon-icon'}
                      style={{ width: '30px', height: '30px' }}
                      name={'MdInfoOutline'} />
            </Column>
            <Column size={10}>
                <Description descriptionContent={
                    <div className={'check-addon-text'}>
                        <span style={{fontSize: '16px'}}> Зачекайте! Перевіряється наявність аддона ASteps.</span>
                    </div>
                }/>
            </Column>
            <Column size={1}/>
        </Row>
    }
}

export default CheckAddonMessage;