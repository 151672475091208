import React from 'react';
import Row from "../../components/Row";
import Space from "../../components/Space";

class NoProject extends React.Component {

    render() {

        return <div style={{ color: '#54b5f1' }}>
            <Space hSize={'100px'}/>
            <Row horizontalAlign={'center'}>
                <div style={{ fontSize: '25px', fontWeight: 'bold' }}>404</div>
            </Row>
            <Space hSize={'10px'}/>
            <Row horizontalAlign={'center'}>
                <div style={{ fontSize: '20px' }}>Проєкту не знайдено</div>
            </Row>
        </div>
    }
}

export default NoProject;