import React from 'react';
import PropTypes from 'prop-types';
import Space from '../Space';
import IconButton from '../IconButton';
import Header from '../Header';

class Content extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: true
        }
    }

    handleClickVisible = () => {
        this.setState({ visible: !this.state.visible });
    }

    get rowStyle() {

        return {
            width: '19px',
            height: '19px',
            color: this.state.visible ? '#a6d7ff' : '#1d9aff'
        };
    }

    get moreButton() {
        return (
            <div>
                <Space hSize={'5px'}/>
                <IconButton style={this.rowStyle}
                            iconName={this.state.visible ? 'MdExpandLess' : 'MdExpandMore'}
                            onClick={this.handleClickVisible} />
            </div>
        );
    }

    get style() {
        const { indent } = this.props;
        const style = { ...this.props.style };

        style.width = (100 - indent * 2) + '%';
        style.marginLeft = indent + '%';

        return style;
    }

    render() {
        return <div style={this.style}>
            <Header preTitleElement={this.moreButton}
                    paddingHeader={'7px 15px 7px 15px'}
                    visibleUnderscore={this.state.visible && !!this.props.children}
                    titleSize={this.props.titleSize}
                    title={this.props.title}
                    style={this.props.style}>
                {this.props.headerElement}
            </Header>
            {this.state.visible && this.props.children}
        </div>
    }
}

Content.propTypes = {
    title: PropTypes.string,
    titleSize: PropTypes.number,
    headerElement: PropTypes.element,
    style: PropTypes.object,
    indent: PropTypes.number
}

Content.defaultProps = {
    title: 'Немає назви',
    titleSize: 17,
    headerElement: null,
    style: {},
    indent: 0
}

export default Content;