import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

class Column extends React.Component {

    static style = {
        display: 'inline-block',
        overflow: 'none',
        width: '100%',
        height: '100%',
        minWidth: '50px'
    }

    updateStyle = () => {
        const style = { ...Column.style };
        const { size, height, scroll } = this.props;

        style.width = (100 * size / 12) + '%';
        if (height) { style.height = height; }

        if (scroll) { style.overflow = 'auto'; }

        return style;
    }

    render() {
        return <div style={this.updateStyle()}
                    className={this.props.className}
                    onClick={this.props.onClick}>
            <div style={this.props.style}
                className={'innerBox'}>
                {this.props.children}
            </div>
        </div>
    }

}

Column.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    size: PropTypes.number,
    height: PropTypes.string,
    scroll: PropTypes.bool,
    onClick: PropTypes.func
}

export default Column;