import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';


import RequestCommand from '../../units/command/request-command';
import CommandType from '../../units/command/command-type';
import FieldListTransformer from '../../units/transformers/field-form-transformer';
import AutomationTransformer from '../../units/transformers/automation-transformer';

import Header from "../../components/Header";
import AStepsProjectDB from "../../units/db/asteps-project-db";
import PetitionSorter from "../../units/sorter/petition-sorter";
import Description from "../../components/Description";
import IconButton from "../../components/IconButton";
import Space from "../../components/Space";
import PetitionTable from "./PetitionTable";
import PetitionDB from "../../units/db/petition-db";
import NoAddonMessage from "../../shared/NoAddonMessage";
import Row from "../../components/Row";
import UserInterface from "../../shared/UserInterface";
import CheckAddonMessage from "../../shared/CheckAddonMessage";
import AStepsStatusMessage from "../../shared/AStepsStatusMessage";

class AStepProject extends React.Component {

    constructor(props) {
        super(props);
        this.fieldListTransformer = new FieldListTransformer();
        this.automationTransformer = new AutomationTransformer();

        const body = {
            fields: {},
            groups: []
        }
        const fieldListForm = this.fieldListTransformer.transformToForm(body.fields);
        const automation = this.automationTransformer.transformTo(body);

        this.state = {
            title: '...',
            fieldListForm,
            body,
            automation,
            automationProgress: 'none',
            checkedAddon: false,
            errorReason: null,
            numberOfConnectionAttempts: 0
        }

        PetitionSorter.addUpdatePetitionIdStatus((notPassedPetitionIds) => {
            const fieldListForm = structuredClone(this.state.fieldListForm);

            fieldListForm.map((item) => {
                const options = item.options;

                if (options.key === 'petitionIds') {
                    options.value = Object.keys(notPassedPetitionIds)?.join() || '';
                }
            });

            this.setState({ fieldListForm }, () => this.updateBody())
        })
    }

    updateBody = () => {
        const body = {};
        body.groups = this.state.body.groups;
        body.fields = this.fieldListTransformer.translateToBody(this.state.fieldListForm);
        this.setState({ body });

        const automation = this.automationTransformer.transformTo(body);
        this.setState({ automation });
    }

    componentDidMount() {
        this.handleRefresh();

        AStepsProjectDB.findProject(this.props.param)
            .then((response) => {
                this.setState({
                    title: response.title,
                    description: response.description,
                })

                return AStepsProjectDB.findAutomation(this.props.param);
            }).then((response) => {
                try {
                    const body = JSON.parse(response.autoSteps);
                    this.handleChangeBodyAutomation(body);
                } catch (e) {}
            }).catch((e) => {
                this.props.navigate('/not-found-project');
            })
    }

    handleRunAutoSteps = () => {

        console.log(this.state.automation);

        if (Object.keys(PetitionSorter.notPassed).length === 0) {
            alert('Нема петицій які треба обробити.');
            return;
        }

        RequestCommand.start({
            url: 'https://google.com/',
            body: this.state.automation
        },(result) => {
            PetitionSorter.update(result);
            this.setState({ errorReason: null });
            this.setState({ automationProgress: result.progress });
        },(errorReason) => {
            if (errorReason) { this.setState({ errorReason }); }
        });
    }

    handleChangeFields = (item, action, result) => {
        let fieldListForm = null;
        console.log(`Item: ${item}, action: ${action}, result:`);
        console.log(result);

        if (item === 'field') {
            if (action === 'add') {
                fieldListForm = this.fieldListTransformer
                    .addField(result.id, result.field, this.state.fieldListForm);
            } else if (action === 'update') {
                fieldListForm = this.fieldListTransformer
                    .updateField(result.id, result.fieldOptions, this.state.fieldListForm);
            } else if (action === 'remove') {
                fieldListForm = this.fieldListTransformer
                    .removeField(result.id, this.state.fieldListForm);
            }
        } else if (item === 'field-dropdown') {
            if (action === 'add') {
                fieldListForm = this.fieldListTransformer
                    .addItem(result.id, result.dropdown, this.state.fieldListForm);
            } else if (action === 'update') {
                fieldListForm = this.fieldListTransformer
                    .updateItem(result.id, result.dropdown, this.state.fieldListForm);
            } else if (action === 'remove') {
                fieldListForm = this.fieldListTransformer
                    .removeItem(result.id, this.state.fieldListForm);
            }
        }

        if (fieldListForm) {
            this.setState({ fieldListForm }, this.updateBody);
        }
    }

    handleChangeBodyAutomation = (body) => {
        const fieldListForm = this.fieldListTransformer.transformToForm(body.fields);
        const automation = this.automationTransformer.transformTo(body);

        const field = fieldListForm.find((field) => field.options.key === 'category');
        const category = field ? field.options.value : null;
        PetitionDB.update(category);

        this.setState({ body, fieldListForm, automation });
    }

    handleRefresh = () => {
        RequestCommand.request(CommandType.CHECK_ADDON, {})
            .then(() => {
                this.setState({ checkedAddon: true, errorReason: null });
            })
            .catch((errorReason) => {
                if (!errorReason) {
                    this.setState({ checkedAddon: true,  errorReason: 'unexpected-error' });
                    return;
                }

                if (this.state.numberOfConnectionAttempts >= 4) {
                    this.setState({ checkedAddon: true,  errorReason });
                } else {
                    const numberOfConnectionAttempts = this.state.numberOfConnectionAttempts + 1;
                    this.setState({ numberOfConnectionAttempts },
                        this.handleRefresh);
                }
            });
    }

    handleNavToAutoStepsViewer = () => {
        this.props.navigate('/auto-steps/projects/view/' + this.props.param);
    }

    render() {

        return <>
            <Header indent={5} title={this.state.title}>
                <Space hSize={'10px'} />
                <Row verticalAlign={'center'}>
                    <IconButton iconName={'MdContentPaste'}
                                tooltipAlign={'left'}
                                tooltipInfo={'Засіб перегляду автоматизованих кроків'}
                                onClick={this.handleNavToAutoStepsViewer}/>
                    <Space wSize={'20px'} />
                    <IconButton style={{ width: '30px', height: '30px' }}
                                iconName={'MdOutlineSend'}
                                buttonStyle={'add'}
                                tooltipAlign={'left'}
                                tooltipInfo={'Запустити'}
                                disabled={!this.state.checkedAddon
                                    || this.state.errorReason === 'no-addon'
                                    || this.state.errorReason === 'timeout'}
                                onClick={this.handleRunAutoSteps} />
                </Row>
            </Header>
            <Row>
                {this.state.automationProgress === 'started' &&
                    <AStepsStatusMessage automationProgress={this.state.automationProgress} />
                }
                {!this.state.checkedAddon &&
                    <CheckAddonMessage />
                }
                {this.state.checkedAddon
                    && this.state.errorReason
                    && (this.state.errorReason === 'no-addon' || this.state.errorReason === 'timeout') &&
                    <NoAddonMessage />
                }
            </Row>
            <Space hSize={'5px'} />
            <Description titleSize={'17px'}
                         title={'Опис'}
                         descriptionSize={'16px'}
                         description={this.state.description}
                         indent={10} />
            <Row>
                {this.state.checkedAddon && !this.state.errorReason &&
                    <UserInterface automationProgress={this.state.automationProgress}
                                   fields={this.state.fieldListForm}
                                   onChange={this.handleChangeFields} />
                }
            </Row>
            {(this.props.param === 'ua-petitions-bank'
                    || this.props.param === 'ua-petitions-diia') &&
                <PetitionTable showNavigatorToPN={true} />
            }
        </>
    }
}

AStepProject.propTypes = {
    param: PropTypes.string
}

export default () => {
    const param = useParams()?.['*'];
    const navigate = useNavigate();

    return (
        <AStepProject param={param} navigate={navigate} />
    )
}