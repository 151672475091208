import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Row from "../../../../components/Row";
import ActionSelect from "../../../../components/ActionSelect";
import Input from "../../../../components/Input";
import IconButton from "../../../../components/IconButton";
import AttributeSelect from "../../../../components/AttrubuteSelect";
import Column from "../../../../components/Column";
import Space from "../../../../components/Space";
import PropTypes from "prop-types";
import Group from "../index";

class Step extends React.Component {

    static createStep() {
        return {
            id: uuidv4(),
            options: {
                action: 'click',
                attribute: 'class',
                value: null,
                text: null
            }
        }
    }

    static elementOptions(options) {
        return {
            action: options.action || 'click',
            attribute: options.attribute || 'class',
            value: options.value || null,
            text: options.text || null
        }
    }

    static navigateOptions(options) {
        return {
            action: options.action || 'navigateTo',
            url: options.url || 'https://'
        }
    }

    selectOptions(action, options = {}) {
        options.action = action;

        if (action === 'navigateTo') {
            return Step.navigateOptions(options);
        } else {
            return Step.elementOptions(options);
        }
    }

    handleActionSelect = (action) => {
        const { id, options } = this.props.step;
        const step = this.selectOptions(action);


        for (let key in step) {

            if (!options) {
                break;
            }

            if (key !== 'action' && options[key]) {
                step[key] = options[key];
            }
        }

        this.handleChange('step', 'update',
            { id, step });
    }
    handleAttributeSelect = (attribute) => this.handleUpdate({ attribute })
    handleChangeValue = (value) => this.handleUpdate({ value })
    handleChangeUrl = (url) => this.handleUpdate({ url })
    handleChangeText = (text) => this.handleUpdate({ text })

    handleAddStep = () => {
        const { id } = this.props.step;
        let step = Step.createStep();

        this.handleChange('step', 'add',
            { id, step });
    }

    handleUpdate = (value) => {
        const { id } = this.props.step;
        const step = { ...this.props.step.options, ...value };

        this.handleChange('step', 'update',
            { id, step });
    }

    handleRemove = () => {
        const { id } = this.props.step;

        this.handleChange('step', 'remove',
            { id });
    }

    handleChange = (item, action, result) => {
        this.props.onChange?.(item, action, result);
    }

    render() {
        const { options } = this.props.step;

        if (options.action === 'navigateTo') {
            return (
                <Row verticalAlign={'center'} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Column size={this.props.editable ? 10 : 11.9}>
                        <Row horizontalAlign={'center'}>
                            <Column size={6}>
                                <ActionSelect action={options.action}
                                              editable={this.props.editable}
                                              onChange={this.handleActionSelect} />
                            </Column>
                            <Column size={6}>
                                <Input value={options.url}
                                       label={'URL'}
                                       onChange={this.handleChangeUrl}/>
                            </Column>
                            {this.props.editable &&
                                <Column size={2}>
                                    <Row horizontalAlign={'center'}>
                                        <IconButton iconName={'MdOutlineAdd'}
                                                    buttonStyle={'add'}
                                                    tooltipAlign={'left'}
                                                    tooltipInfo={'Додати крок'}
                                                    onClick={this.handleAddStep}/>
                                        <IconButton iconName={'MdOutlineRemove'}
                                                    buttonStyle={'remove'}
                                                    tooltipAlign={'left'}
                                                    tooltipInfo={'Видалити крок'}
                                                    onClick={this.handleRemove}/>
                                    </Row>
                                </Column>
                            }
                        </Row>
                    </Column>
                </Row>
            );
        } else {
            return (
                <Row verticalAlign={'center'} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Column size={this.props.editable ? 10 : 11.9}>
                        <Row horizontalAlign={'center'}>
                            <Column size={options.action !== 'findElement' ? 3 : 4}>
                                <ActionSelect action={options.action}
                                              editable={this.props.editable}
                                              onChange={this.handleActionSelect} />
                            </Column>
                            <Column size={options.action !== 'findElement' ? 3 : 4}>
                                <AttributeSelect attribute={options.attribute}
                                                 editable={this.props.editable}
                                                 onChange={this.handleAttributeSelect}/>
                            </Column>
                            <Column size={options.action !== 'findElement' ? 3 : 4}>
                                <Input value={options.value}
                                       label={'Запит'}
                                       required={true}
                                       onChange={this.handleChangeValue}/>
                            </Column>
                            {options.action !== 'findElement' &&
                                <Column size={3}>
                                    <Input value={options.text}
                                           label={'Текст'}
                                           onChange={this.handleChangeText}/>
                                </Column>
                            }
                        </Row>
                    </Column>
                    {this.props.editable &&
                        <Column size={2}>
                            <Row horizontalAlign={'center'}>
                                <IconButton iconName={'MdOutlineAdd'}
                                            buttonStyle={'add'}
                                            tooltipAlign={'left'}
                                            tooltipInfo={'Додати крок'}
                                            onClick={this.handleAddStep}/>
                                <Space wSize={'5px'} />
                                <IconButton iconName={'MdOutlineRemove'}
                                            buttonStyle={'remove'}
                                            tooltipAlign={'left'}
                                            tooltipInfo={'Видалити крок'}
                                            onClick={this.handleRemove}/>
                            </Row>
                        </Column>
                    }
                </Row>
            );
        }
    }
}

Step.propTypes = {
    editable: PropTypes.bool
}

Step.defaultProps = {
    editable: true
}

export default Step;