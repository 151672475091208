import React from 'react';
import PropTypes from 'prop-types';
import Description from '../../components/Description';
import Column from '../../components/Column';
import Icon from '../../components/Icon';
import Row from '../../components/Row';
import './index.css';

class AStepsStatusMessage extends React.Component {

    render() {

        return <Row className={'asteps-status-container'} verticalAlign={'center'}>
            <Column size={1} style={{ alignItems: 'center' }}>
                <Icon className={'asteps-status-icon'}
                      style={{ width: '30px', height: '30px' }}
                      name={'MdInfoOutline'} />
            </Column>
            <Column size={10}>
                <Description descriptionContent={
                    <div style={{fontSize: '16px'}} className={'asteps-status-text'}>
                        <div> Прцюють автоматичні кроки.</div>
                    </div>
                }/>
            </Column>
            <Column size={1}/>
        </Row>
    }
}

AStepsStatusMessage.propTypes = {
    automationProgress: PropTypes.string
}

export default AStepsStatusMessage;