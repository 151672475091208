import React from 'react';
import Row from '../Row';
import PropTypes from 'prop-types';
import './index.css';
import Icon from '../Icon';
import IconButton from '../IconButton';

class Card extends React.Component {

    static titleStyle = {
        primitive: {
            color: 'rgba(0,197,62,0.96)',
            backgroundColor: 'rgba(0,203,64,0.09)',
            width: '100%',
            height: '45px'
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            visible: true
        }
    }

    handleClick = () => {
        const visible = !this.state.visible;
        this.setState({ visible });
        this.props.onChangeVisible?.(visible);
    }

    get updateCardContainerStyle() {
        const style = { ...this.props.cardStyle };

        if (this.props.view === 'inner') {
            style.border = 'none';
        }

        return style;
    }

    get cardTitleStyle() {
        const style = {};

        if (this.props.view === 'inner') {
            style.fontSize = 'medium';
            style.color = '#1d9aff';
            style.backgroundColor = '#FFF'
        }

        return style;
    }

    updateCardContentStyle(hasHeader) {
        const style = {};
        const { maxHeight } = this.props;

        if (!this.state.visible) {
            style.display = 'none';
        }

        if (maxHeight?.includes('px')) {
            let height = maxHeight;

            if (!hasHeader) {
                const mh = maxHeight.replace('px', '');
                height = +mh + 51;
            }
            style.maxHeight = height;
            style.height = height;
        }

        return style;
    }

    get rowIcon() {
        return this.state.visible ? 'MdKeyboardArrowUp' : 'MdKeyboardArrowDown';
    }

    getHeader() {
        const titleStyle = this.props.view === 'inner' ? {} : Card.titleStyle['primitive'];

        if (this.state.visible) {
            titleStyle.paddingBottom = '5px';
        }

        if (this.props.behaviorAs !== 'ableToHide') {

            if (!this.props.title && !this.props.headerElement) {
                return null;
            }

            return (
                <Row horizontalAlign={this.props.headerAlign}
                     style={titleStyle}
                     verticalAlign={'center'}>
                    {this.props.preTitleIcon &&
                        <Icon name={this.props.preTitleIcon}
                              style={{
                                  color: titleStyle.color,
                                  width: '20px',
                                  height: '20px',
                                  marginLeft: '5px'
                              }} />
                    }
                    {this.props.title &&
                        <span className={'card-title'}
                              style={this.cardTitleStyle}>
                            {this.props.title}
                        </span>
                    }
                    {this.props.headerElement}
                </Row>
            );
        }

        if (this.props.behaviorAs === 'ableToHide') {

            if (this.props.headerElement) {
                return (
                    <Row horizontalAlign={this.props.headerAlign}
                         style={titleStyle}
                         verticalAlign={'center'}>
                        <IconButton style={{ width: '25px', height: '25px', margin: '5px' }}
                                    iconName={this.rowIcon}
                                    onClick={this.handleClick}/>
                        {this.props.title &&
                            <span className={'card-title'}
                                  style={this.cardTitleStyle}>
                                {this.props.title}
                            </span>
                        }
                        {this.props.headerElement}
                    </Row>
                );
            } else {
                return (
                    <Row behaviorAs={'button'}
                         style={titleStyle}
                         horizontalAlign={this.props.headerAlign}
                         verticalAlign={'center'}
                         onClick={this.handleClick}>
                        <Icon style={{ color: '#FFF' }} name={this.rowIcon}/>
                        {this.props.title &&
                            <span className={'card-title'}
                                  style={this.cardTitleStyle}>
                                {this.props.title}
                            </span>
                        }
                    </Row>
                );
            }
        }
    }

    render() {
        const header = this.getHeader();

        return <div className={'card-container'}
                    style={this.updateCardContainerStyle}>
            {header}
            <Row style={this.updateCardContentStyle(header !== null)}
                 scroll={true}>
                <div className={'card-content'}>
                    {this.props.children}
                </div>
            </Row>
        </div>
    }

}

Card.propTypes = {
    headerElement: PropTypes.element,
    view: PropTypes.string,
    preTitleIcon: PropTypes.string,
    title: PropTypes.string,
    headerAlign: PropTypes.string,
    behaviorAs: PropTypes.string,
    maxHeight: PropTypes.string,
    cardStyle: PropTypes.object,
    onChangeVisible: PropTypes.func
}

Card.defaultProps = {
    cardStyle: {}
}

export default Card;