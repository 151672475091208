import Logger from '../logger';

export default class AutomationTransformer {

    #log = new Logger('AutomationTransformer');

    transformTo(body) {
        const groups = [];

        for (let group of body.groups) {
            let steps = this.convertParamToValue(group.steps, body.fields);
            this.generateGroups(groups, steps, body.fields, group.loopKey);
        }

        return { tabId: null, groups };
    }

    convertParamToValue(steps, fields) {
        const result = [];

        for (let step of steps) {
            let s = {};
            for (let stepKey in step) {
                const value = step[stepKey];

                if (typeof value === 'string') {
                    if (this.hasDoubleConversion(value)) {
                        const modifiedValue = this.replaceToFieldValue(value, fields, /(?<=\$\{\$\{).+?(?=\}\})/g);
                        s[stepKey] = this.replaceToFieldValue(modifiedValue, fields, /(?<=\$\{).+?(?=\})/g);
                    } else {
                        s[stepKey] = this.replaceToFieldValue(value, fields, /(?<=\$\{).+?(?=\})/g);
                    }
                } else {
                    s[stepKey] = value;
                }
            }
            result.push(s);
        }

        return result;
    }

    hasDoubleConversion(txt) {
        return txt.match(/(?<=\$\{\$\{).+?(?=\}\})/g)?.length !== 0;
    }

    replaceToFieldValue(txt, fields, regex) {
        const keys = txt.match(regex);

        if (keys && keys.length !== 0) {
            for (let key of keys) {
                const field = fields[key];

                if (field && field.type !== 'array') {
                    txt = txt.replace('${' + key + '}', field.value);
                }
            }
        }

        return txt;
    }

    generateGroups(destGroup, steps, fields, loopKey) {
        const loopValues = this.#getLoopValues(loopKey, fields);

        if (!loopValues || loopValues.length === 0) {
            const g = {};
            g.steps = steps;
            destGroup.push(g);
            return;
        }

        for (let loopValue of loopValues) {
            const g = {};
            const sl = [];

            for (let step of steps) {
                sl.push(this.replaceInObject(step, loopKey, loopValue));
            }

            g.steps = sl;
            destGroup.push(g);
        }
    }

    replaceInObject(obj, from, to) {
        let result = {};

        for (let key in obj) {
            if (typeof obj[key] === 'string') {
                result[key] = obj[key].replace('${' + from + '}', to);
            } else if (typeof obj[key] === 'object'
                && !Array.isArray(obj[key])
                && obj[key] !== null)
            {   // if object
                result[key] = this.replaceInObject(obj[key], from, to);
            } else {
                result[key] = obj[key];
            }
        }

        return result;
    }

    #getLoopValues(loopKey, fields) {
        let result = [];

        if (loopKey) {
            const field = fields[loopKey];

            if (!field) {
                this.#log.warn(`Not found the field with key: ${loopKey}`);
                return;
            }

            if (field.type === 'array') {
                result = field.value.split(',').map((value) => value.trim());
            } else {
                this.#log.warn(`Field with key: ${loopKey} is not array`);
            }
        }

        return result;
    }

}