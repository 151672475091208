import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import Row from '../Row';
import Icon from '../Icon';

class TextArea extends React.Component {

    static textAreaContent = {
        'primitive': 'primitive-textarea',
        'error': 'error-textarea',
        'disabled': 'disabled-textarea'
    }

    static textAreaLabel = {
        'primitive': 'primitive-label',
        'error': 'error-label',
        'disabled': 'disabled-label'
    }

    constructor(props) {
        super(props);

        this.state = {
            focused: false,
            errorMessages: '',
            dirty: false
        }
    }

    static getDerivedStateFromProps(props, state) {

        // if (!props.dirty && !state.dirty) {
        //     return {
        //         focused: state.focused,
        //         errorMessages: ''
        //     };
        // }

        let errorMessages = '';

        if (props.required && !props.value) {
            errorMessages += 'Порожнє поле';
        }

        if (props.maxSize !== 0 && props.maxSize < props.value.length) {
            if (errorMessages) {  errorMessages += '\n'; }
            errorMessages += 'Багато символів';
        }

        if (state.errorMessages !== errorMessages) {
            props.onValidate?.(errorMessages);
        }

        return {
            focused: state.focused,
            errorMessages
        };
    }

    handelChange = (event) => {
        let { value } = event.target;

        if (value.length > this.props.maxSize) {
            value = value.substring(0, this.props.maxSize);
        }

        this.setState({ dirty: true, value });
        this.props.onChange?.(value, this.props.args);
        event.preventDefault();
    }

    handleFocus = (event) => this.handleUpdate(event, true);

    handleBlur = (event) => this.handleUpdate(event, false);

    handleUpdate = (event, focused) => {
        this.setState({ focused });
        event.preventDefault();
    }

    get inputContentStyle() {
        const style = {...this.props.textAreaStyle};

        if (this.state.focused) {
            style.outline = 'none';
        }

        return style;
    }

    get labelContentStyle() {
        const style = {};

        if (this.props.value) {
            style.top = '0%';
        }

        return style;
    }

    get labelStyle() {
        const style = {...this.props.labelStyle};

        if (this.props.disabled) {
            style.color = '#5d5d5d';
        }

        if (this.props.value) {
            style.fontSize = '11px';
            style.fontWeight = 'bold';
        }

        return style;
    }

    updateInputContent = (errorMessages) => {
        let classNames = 'textarea-content';

        if (this.props.disabled) {
            classNames += ' ' + TextArea.textAreaContent['disabled'];
        } else if ((this.props.dirty || this.state.dirty) && errorMessages) {
            classNames += ' ' + TextArea.textAreaContent['error'];
        } else {
            classNames += ' ' + TextArea.textAreaContent['primitive'];
        }

        return classNames;
    }

    updateInputLabel = (errorMessages) => {
        let classNames = 'textarea-label';

        if (this.props.disabled) {
            classNames += ' ' + TextArea.textAreaLabel['disabled'];
        } else if ((this.props.dirty || this.state.dirty) && errorMessages) {
            classNames += ' ' + TextArea.textAreaLabel['error'];
        } else {
            classNames += ' ' + TextArea.textAreaLabel['primitive'];
        }

        return classNames;
    }

    render() {
        const { errorMessages } = this.state;

        return (
            <div style={this.props.containerStyle} className={'textarea-body'}>
                <Row>
                    {(this.props.dirty || this.state.dirty) && errorMessages &&
                        <Icon style={{ width: '15px', height: '15px', marginTop: '7px'}}
                              tooltipAlign={this.props.tooltipAlign}
                              className={'icon-error'}
                              name={'MdErrorOutline'}
                              tooltipStyle={'error'}
                              tooltipMessage={errorMessages}/>
                    }
                    <div className={'textarea-container'}>
                        <textarea ref={(input) => this._input = input}
                               className={this.updateInputContent(errorMessages)}
                               style={this.inputContentStyle}
                               disabled={this.props.disabled}
                               value={this.props.value}
                               onChange={this.handelChange}
                               onFocus={this.handleFocus}
                               onBlur={this.handleBlur}/>
                        <div className={'textarea-label-content'}
                             style={this.labelContentStyle}
                             onClick={() => this._input.focus()}>
                            <div className={this.updateInputLabel(errorMessages)}
                                 style={this.labelStyle}>
                                {this.props.required &&
                                    <span className={'required-textarea'}>* </span>
                                }
                                {this.props.label}
                            </div>
                        </div>
                        {this.props.maxSize !== 0 &&
                            <div className={'textarea-counter-content'}>
                                <span>{this.props.value.length} / {this.props.maxSize}</span>
                            </div>
                        }
                    </div>
                </Row>
            </div>
        );
    }
}

TextArea.propTypes = {
    disabled: PropTypes.bool,
    dirty: PropTypes.bool,
    required: PropTypes.bool,
    maxSize: PropTypes.number,
    value: PropTypes.string,
    label: PropTypes.string,
    containerStyle: PropTypes.object,
    tooltipAlign: PropTypes.string,
    labelStyle: PropTypes.object,
    textAreaStyle: PropTypes.object,
    onValidate: PropTypes.func
}

TextArea.defaultProps = {
    textAreaStyle: {}
}

TextArea.defaultProps = {
    value: '',
    maxSize: 0
}

export default TextArea;