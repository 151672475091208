let Duration = {

    setDuration(ms) {
        return new Promise((resolve) => {
            this.timeout = setTimeout(resolve, ms);
        });
    },

    clearDuration() {
        clearTimeout(this.timeout);
    }

}

export default Duration;