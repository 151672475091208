import React from 'react';
import PropTypes from 'prop-types';
import {NavLink, Outlet, useLocation} from 'react-router-dom';
import Column from '../../../components/Column';
import Card from '../../../components/Card';
import './index.css';
import Logo from '../../../components/Logo';
import Icon from "../../../components/Icon";
import Space from "../../../components/Space";
import Row from "../../../components/Row";

class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            isVertical: window.innerWidth < window.innerHeight
        }

        window.addEventListener("resize", () => {
            this.setState({
                width: window.innerWidth,
                height: window.innerHeight,
                isVertical: window.innerWidth < window.innerHeight
            });
        });
    }

    checkActive = ({ isActive }) => (isActive ? 'active-nav' : 'inactive-nav');

    render() {

        return <Row className={'layout-container'}>
            {!this.state.isVertical &&
                <Column size={2} />
            }
            <Column size={this.state.isVertical ? 12 : 8}>
                <nav className="header-wrapper">
                    <div className="logo">
                        <NavLink to="/" className="brand">
                            <Logo />
                        </NavLink>
                    </div>
                    <div className="nav-wrapper">
                        <div className="navigation">
                            <Space hSize={'7px'} />
                            <NavLink to="/"
                                     className={this.checkActive}>
                                <Icon className={'tab'} name={'MdOutlineHome'} />
                            </NavLink>
                            <NavLink to="/auto-steps/projects"
                                     className={this.checkActive}>
                                <Icon className={'tab'} name={'MdList'} />
                            </NavLink>
                            <NavLink to="/auto-steps/builder"
                                     caseSensitive={true}
                                     className={this.checkActive}>
                                <Icon className={'tab'} name={'MdCode'} />
                            </NavLink>
                        </div>
                    </div>
                </nav>
                <Card preTitleIcon={'MdOutlineInfo'}
                      cardStyle={{
                          border: '1px solid #DDD'
                      }}
                      maxHeight={(this.state.height - 130) + 'px'} >
                    <Outlet />
                </Card>
            </Column>
            {!this.state.isVertical &&
                <Column size={2} />
            }
        </Row>
    }
}

Layout.propTypes = {
    title: PropTypes.string
}

export default () => {
    const location = useLocation();
    return <Layout path={location.pathname} />
};