import React from 'react';
import PropTypes from 'prop-types';
import Column from '../../Column';
import Description from '../../Description';
import Input from '../../Input';
import Space from '../../Space';
import Row from '../../Row';
import './index.css';
import TextArea from '../../TextArea';
import ASUpload from '../../ASUpload';
import { v4 as uuidv4 } from 'uuid';
import JsonView from '@uiw/react-json-view';
import Select from "../../Select";

class FormItem extends React.Component {

    static createSelect({ title, description, descriptionContent, options, value }) {

        return {
            id: uuidv4(),
            inputType: 'select',
            title: title || '',
            description: description || '',
            descriptionContent: descriptionContent || null,
            options: options || [],
            value: value || '',
            hasError: false,
            dirty: false
        }
    }

    static createInput({ required, justLatin, title, description, descriptionContent, unique, value, maxSize }) {

        return {
            id: uuidv4(),
            inputType: 'input',
            required: required || false,
            justLatin: justLatin || false,
            title: title || '',
            description: description || '',
            descriptionContent: descriptionContent || null,
            unique: unique || [],
            value: value || '',
            maxSize: maxSize || 0,
            hasError: false,
            dirty: false
        }
    }

    static createNumber({ required, title, description, descriptionContent, unique, value, maxSize }) {

        return {
            id: uuidv4(),
            inputType: 'number',
            required: required || false,
            title: title || '',
            description: description || '',
            descriptionContent: descriptionContent || null,
            unique: unique || [],
            value: value || '',
            maxSize: maxSize || 0,
            hasError: false,
            dirty: false
        }
    }

    static createTextArea({ required, title, description, descriptionContent, value, maxSize }) {

        return {
            id: uuidv4(),
            inputType: 'textarea',
            required: required || false,
            title: title || '',
            description: description || '',
            descriptionContent: descriptionContent || null,
            value: value || '',
            maxSize: maxSize || 0,
            hasError: false,
            dirty: false
        }
    }

    static createUploader({ required, title, descriptionContent, value }) {

        return {
            id: uuidv4(),
            inputType: 'uploader',
            required: required || false,
            title: title || '',
            descriptionContent: descriptionContent || null,
            value: value || '',
            hasError: false,
            dirty: false
        }
    }

    static textAreaStyle = {
        height: '100px',
        minHeight: '20px',
        maxHeight: '100px',
        width: '90%',
        minWidth: '70%',
        maxWidth: '90%'
    }

    handleChange = (value) => {
        this.props.onChange(this.props.id, value);
    }

    handleValidate = (errorMessages) => {
        this.props.onValidate(this.props.id, errorMessages);
    }

    render() {
        return <>
            <Row className={'form-item-container'}>
                <Column size={6} className={'form-item-description'}>
                    <Description title={this.props.title}
                                 descriptionContent={this.props.descriptionContent}
                                 description={this.props.description} />
                </Column>
                <Column size={6} className={'form-item-input'}>
                    <Space hSize={'10px'} />
                    {this.props.inputType === 'textarea' &&
                        <TextArea textAreaStyle={FormItem.textAreaStyle}
                                  containerStyle={{ marginLeft: '10%', width: '70%' }}
                                  dirty={this.props.dirty}
                                  required={this.props.required}
                                  value={this.props.value}
                                  maxSize={this.props.maxSize}
                                  tooltipAlign={'left'}
                                  label={this.props.title}
                                  onChange={this.handleChange}
                                  onValidate={this.handleValidate}/>
                    }
                    {(this.props.inputType === 'input' || this.props.inputType === 'number') &&
                        <Input containerStyle={{ marginLeft: '10%', width: '70%' }}
                               type={this.props.inputType}
                               dirty={this.props.dirty}
                               required={this.props.required}
                               justLatin={this.props.justLatin}
                               unique={this.props.unique}
                               value={this.props.value}
                               maxSize={this.props.maxSize}
                               inputStyle={{ width: '100%'}}
                               tooltipAlign={'left'}
                               label={this.props.title}
                               onChange={this.handleChange}
                               onValidate={this.handleValidate}/>
                    }
                    {this.props.inputType === 'select' &&
                        <Select containerStyle={{ marginLeft: '10%', width: '70%' }}
                                label={this.props.title}
                                value={this.props.value}
                                defaultValue={'none'}
                                options={this.props.options}
                                onChange={this.handleChange}/>
                    }
                    {this.props.inputType === 'uploader' &&
                        <ASUpload dirty={this.props.dirty}
                                  required={this.props.required}
                                  value={this.props.value}
                                  onLoad={this.handleChange}
                                  onValidate={this.handleValidate}/>
                    }
                </Column>
            </Row>
            {this.props.inputType === 'uploader' && this.props.value &&
                <Row className={'json-form-item-container'}>
                    <JsonView value={JSON.parse(this.props.value)} />
                </Row>
            }
        </>
    }
}

FormItem.propTypes = {
    inputType: PropTypes.string,
    id: PropTypes.string.isRequired,
    dirty: PropTypes.bool,
    required: PropTypes.bool,
    justLatin: PropTypes.bool,
    title: PropTypes.string,
    descriptionContent: PropTypes.element,
    description: PropTypes.string,
    unique: PropTypes.array,
    options: PropTypes.array,
    value: PropTypes.string,
    maxSize: PropTypes.number,
    onChange: PropTypes.func,
    onValidate: PropTypes.func
}

FormItem.defaultProps = {
    inputType: 'input',
    required: false,
    justLatin: false
}

export default FormItem;