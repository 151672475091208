import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import RequestCommand from '../../units/command/request-command';
import CommandType from '../../units/command/command-type';
import FieldListTransformer from '../../units/transformers/field-form-transformer';
import AutomationTransformer from '../../units/transformers/automation-transformer';
import GroupListTransformer from '../../units/transformers/group-form-transformer';
import JSONFile from '../../units/file';

import Header from "../../components/Header";
import PetitionSorter from "../../units/sorter/petition-sorter";
import IconButton from "../../components/IconButton";
import Space from "../../components/Space";
import NoAddonMessage from "../../shared/NoAddonMessage";
import Row from "../../components/Row";
import UserInterface from "../../shared/UserInterface";
import CheckAddonMessage from "../../shared/CheckAddonMessage";
import AStepsStatusMessage from "../../shared/AStepsStatusMessage";
import UserInterfaceEditor from "../../shared/UserInterfaceEditor";
import AutoStepsEditor from "../../shared/AutoStepsEditor";
import IconASUpload from "../../components/IconASUpload";
import Description from "../../components/Description";

class AStepsBuilder extends React.Component {

    _uiErrors = {};

    constructor(props) {
        super(props);
        this.fieldListTransformer = new FieldListTransformer();
        this.groupListTransformer = new GroupListTransformer();
        this.automationTransformer = new AutomationTransformer();

        const fieldListForm = this.fieldListTransformer.transformToForm({});
        const groupListForm = this.groupListTransformer.transformToForm([]);

        const body = {};
        body.groups = this.groupListTransformer.translateToBody(groupListForm);
        body.fields = this.fieldListTransformer.translateToBody(fieldListForm);

        const automation = this.automationTransformer.transformTo(body);

        this.state = {
            fieldListForm,
            groupListForm,
            body,
            automation,
            automationProgress: 'none',
            checkedAddon: false,
            addonErrorReason: null,
            numberOfConnectionAttempts: 0,
            dirty: false,
            hasError: false,
            uiErrors: {}
        }
    }

    updateBody = () => {
        const body = {};
        body.groups = this.groupListTransformer.translateToBody(this.state.groupListForm);
        body.fields = this.fieldListTransformer.translateToBody(this.state.fieldListForm);
        this.setState({ body });

        const automation = this.automationTransformer.transformTo(body);
        this.setState({ automation });

        if (Object.keys(body.fields).length === 0) {
            this.setState({ hasError: false, uiErrors: {} });
        }
    }

    componentDidMount() {
        this.handleRefresh();
    }

    handleDirty = () => {
        this.setState({ dirty: true });
    }

    handleRunAutoSteps = () => {

        // console.log(this.state.automation);
        //
        // if (Object.keys(PetitionSorter.notPassed).length === 0) {
        //     alert('Нема петицій які треба обробити.');
        //     return;
        // }

        if (this.state.hasError) {
            this.handleDirty();
            return;
        }

        RequestCommand.start({
            url: 'https://google.com/',
            body: this.state.automation
        },(result) => {
            PetitionSorter.update(result);
            this.setState({ addonErrorReason: null });
            this.setState({ automationProgress: result.progress });
        },(addonErrorReason) => {
            if (addonErrorReason) { this.setState({ addonErrorReason }); }
        });
    }

    handleDownload = () => {
        const fileName = prompt('Введіть назву JSON файла');

        if (fileName) {
            const body = {};
            body.groups = this.groupListTransformer.translateToBody(this.state.groupListForm);
            body.fields = this.fieldListTransformer.translateToBody(this.state.fieldListForm);
            JSONFile.write(fileName, body).then(alert, alert);
        }
    }

    handleUpdateData = ({ fields, groups }) => {
        const result = {};
        result.dirty = true;
        result.fieldListForm = this.fieldListTransformer.transformToForm(fields || {});
        result.groupListForm = this.groupListTransformer.transformToForm(groups || []);
        result.body = {};
        result.body.groups = this.groupListTransformer.translateToBody(this.state.groupListForm);
        result.body.fields = this.fieldListTransformer.translateToBody(this.state.fieldListForm);
        result.automation = this.automationTransformer.transformTo(this.state.body);
        this.setState(result);
    }

    handleChangeFields = (item, action, result) => {
        let fieldListForm = null;

        if (item === 'field') {
            if (action === 'add') {
                fieldListForm = this.fieldListTransformer
                    .addField(result.id, result.field, this.state.fieldListForm);
            } else if (action === 'update') {
                fieldListForm = this.fieldListTransformer
                    .updateField(result.id, result.fieldOptions, this.state.fieldListForm);
            } else if (action === 'remove') {
                fieldListForm = this.fieldListTransformer
                    .removeField(result.id, this.state.fieldListForm);
            }
        } else if (item === 'field-dropdown') {
            if (action === 'add') {
                fieldListForm = this.fieldListTransformer
                    .addItem(result.id, result.dropdown, this.state.fieldListForm);
            } else if (action === 'update') {
                fieldListForm = this.fieldListTransformer
                    .updateItem(result.id, result.dropdown, this.state.fieldListForm);
            } else if (action === 'remove') {
                fieldListForm = this.fieldListTransformer
                    .removeItem(result.id, this.state.fieldListForm);
            }
        }

        if (fieldListForm) {
            this.setState({ fieldListForm }, this.updateBody);
        }
    }

    handleChangeGroups = (item, action, result) => {
        let groupListForm = null;

        console.log(`Item: ${item}, action: ${action}, result:`);
        console.log(result);

        if (item === 'group') {
            if (action === 'add') {
                groupListForm = this.groupListTransformer
                    .addGroup(result.id, result.group, this.state.groupListForm);
            } else if (action === 'update') {
                groupListForm = this.groupListTransformer
                    .updateGroup(result.id, result.group, this.state.groupListForm);
            } else if (action === 'remove') {
                groupListForm = this.groupListTransformer
                    .removeGroup(result.id, this.state.groupListForm);
            }
        } else if (item === 'step') {

            if (action === 'addFirst') {
                groupListForm = this.groupListTransformer
                    .addFirstStep(result.groupId, result.step, this.state.groupListForm);
            } else if (action === 'add') {
                groupListForm = this.groupListTransformer
                    .addStep(result.id, result.step, this.state.groupListForm);
            } else if (action === 'update') {
                groupListForm = this.groupListTransformer
                    .updateStep(result.id, result.step, this.state.groupListForm);
            } else if (action === 'remove') {
                groupListForm = this.groupListTransformer
                    .removeStep(result.id, this.state.groupListForm);
            }
        }

        if (groupListForm) {
            this.setState({ groupListForm }, this.updateBody);
        }
    }

    handleRefresh = () => {
        RequestCommand.request(CommandType.CHECK_ADDON, {})
            .then(() => {
                this.setState({ checkedAddon: true, addonErrorReason: null });
            })
            .catch((addonErrorReason) => {
                if (!addonErrorReason) {
                    this.setState({ checkedAddon: true,  addonErrorReason: 'unexpected-error' });
                    return;
                }

                if (this.state.numberOfConnectionAttempts >= 4) {
                    this.setState({ checkedAddon: true,  addonErrorReason });
                } else {
                    const numberOfConnectionAttempts = this.state.numberOfConnectionAttempts + 1;
                    this.setState({ numberOfConnectionAttempts },
                        this.handleRefresh);
                }
            });
    }

    handlerValidate = (key, errorMessages) => {

        if (errorMessages) {
            this._uiErrors[key] = errorMessages;
        } else {
            delete this._uiErrors[key];
        }

        console.log(this._uiErrors);
        if (Object.keys(this._uiErrors).length !== 0) {
            this.setState({
                uiErrors: this._uiErrors,
                hasError: true
            });
        } else {
            this.setState({
                uiErrors: {},
                hasError: false,
                dirty: false
            });
        }
    }

    render() {

        return <>
            <Header indent={5} title={'Створити автоматизовані кроки'}>
                <Space hSize={'10px'} />
                <Row verticalAlign={'center'}>
                    <IconButton iconName={'MdDownload'}
                                tooltipAlign={'left'}
                                tooltipInfo={'Зберегти на PC'}
                                onClick={this.handleDownload}/>
                    <Space wSize={'10px'} />
                    <IconASUpload onLoad={this.handleUpdateData} />
                    <Space wSize={'10px'} />
                    <IconButton style={{ width: '30px', height: '30px' }}
                                iconName={'MdOutlineSend'}
                                buttonStyle={'add'}
                                tooltipAlign={'left'}
                                tooltipInfo={'Запустити'}
                                disabled={(this.state.dirty && this.state.hasError)
                                    || !this.state.checkedAddon
                                    || this.state.addonErrorReason === 'no-addon'
                                    || this.state.addonErrorReason === 'timeout'}
                                onClick={this.handleRunAutoSteps} />
                </Row>
            </Header>
            <Row>
                <Description indent={7} titleSize={'16px'}
                             title={'Опис'}
                             descriptionSize={'16px'}
                             description={'Користувач може створити власні кроки для будь-якого сайту використовуючи редактори автоматичних кроків та інтерфейсу.'} />
            </Row>
            <Row>
                {this.state.automationProgress === 'started' &&
                    <AStepsStatusMessage automationProgress={this.state.automationProgress} />
                }
                {!this.state.checkedAddon &&
                    <CheckAddonMessage />
                }
                {this.state.checkedAddon
                    && this.state.addonErrorReason
                    && (this.state.addonErrorReason === 'no-addon' || this.state.addonErrorReason === 'timeout') &&
                    <NoAddonMessage />
                }
            </Row>
            <Row>
                <UserInterfaceEditor indent={7}
                                     hasError={this.state.hasError}
                                     uiErrors={this.state.uiErrors}
                                     dirty={this.state.dirty}
                                     fields={this.state.fieldListForm}
                                     onChange={this.handleChangeFields}
                                     onValidate={this.handlerValidate}
                                     onDirty={this.handleDirty} />
            </Row>
            <Row>
                {this.state.checkedAddon && !this.state.addonErrorReason &&
                    <UserInterface automationProgress={this.state.automationProgress}
                                   fields={this.state.fieldListForm}
                                   onChange={this.handleChangeFields} />
                }
            </Row>
            <Row>
                <AutoStepsEditor fields={this.state.fieldListForm}
                                 groups={this.state.groupListForm}
                                 onChange={this.handleChangeGroups} />
            </Row>
        </>
    }
}

export default AStepsBuilder;