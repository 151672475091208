import { v4 as uuidv4 } from 'uuid';

class DropDownTransformer {

    addItem(id, newItem, fields) {
        return this.#findItem(id, fields,
            (item, index, items) => items.splice(index + 1, -1, newItem));
    }

    updateItem(id, updatedItem, fields) {
        return this.#findItem(id, fields,
            (item) => {
                item.value = updatedItem.value;
                item.title = updatedItem.title;
            });
    }

    removeItem(id, fields) {
        return this.#findItem(id, fields,
            (item, index, items) => { items.splice(index, 1) });
    }

    #findItem(id, fields, callback) {

        return fields.map((field) => {
            let index = 0;
            let items = field.options.items;

            if (id === -1) {
                callback(items[0] || null, -1, items);
                return field;
            }

            for (let item of items) {
                if (item.id === id) {
                    callback(item, index, field.options.items);
                    break;
                }
                index++;
            }

            return field;
        });
    }

    transformToForm(items) {
        const result = [];
        items?.forEach((item) => result.push({ id: uuidv4(), ...item }));
        return result;
    }

    translateToBody(items) {
        const result = [];
        items?.forEach((item) => {
            const { value, title } = item;

            if (value && title) {
                result.push({value, title});
            }
        });
        return result;
    }
}

export default class FieldListTransformer {

    constructor() {
        this._dropDownTransformer = new DropDownTransformer();
    }

    addField(id, newField, originFields) {
        const copiedFields = structuredClone(originFields);
        this.#findField(id, copiedFields,
            (field, index, fields) => fields.splice(index + 1, -1, newField));
        return copiedFields;
    }

    updateField(id, fieldOptions, originFields) {
        const copiedFields = structuredClone(originFields);
        this.#findField(id, copiedFields, (field) => {
            if (field) {
                field.options = fieldOptions;
            } else {
                console.log('Unable to update the options, field is null');
            }
        });
        return copiedFields;
    }

    removeField(id, originFields) {
        const copiedFields = structuredClone(originFields);
        this.#findField(id, copiedFields, (field, index, fields) => { fields.splice(index, 1) });
        return copiedFields;
    }

    #findField(id, fields, callback) {

        if (id === -1) {
            callback(fields[0] || null, -1, fields);
            return;
        }

        let index = 0;
        for (let field of fields) {
            if (field.id === id) {
                callback(field, index, fields);
                break;
            }
            index++;
        }
    }

    /**
     * Transforms FROM
     *
     * {
     *  petitionIds: { type: 'array', title: 'Номери потиції', value:'234, 212, 234' }
     * }
     *
     * TO
     *
     * [
     *     {
     *         id: 1,
     *         options: {
     *             key: 'petitionIds',
     *             type: 'array',
     *             title: 'Номери потиції',
     *             value: '234, 212, 234'
     *         }
     *     }
     * ]
    * */
    transformToForm(fields) {
        const result = [];

        for (let key in fields) {
            const field = {};
            field.id = uuidv4();
            field.options = {};
            field.options.key = key;
            field.options.type = fields[key].type;
            field.options.visible = fields[key].visible ?? true;
            field.options.title = fields[key].title;
            field.options.description = fields[key].description;
            field.options.value = fields[key].value;
            field.options.items = this._dropDownTransformer.transformToForm(fields[key].items);
            result.push(field);
        }

        return result;
    }

    /**
     * Transforms FROM
     * [
     *     {
     *         id: 1,
     *         options: {
     *             key: 'petitionIds',
     *             type: 'array',
     *             title: 'Номери потиції',
     *             value: '234, 212, 234'
     *         }
     *     }
     * ]
     *
     * TO
     *
     * {
     *  petitionIds: { type: 'array', title: 'Номери потиції', value:'234, 212, 234' }
     * }
     * */
    translateToBody(fields) {
        const result = {};

        for (let field of fields) {
            const key = field.options.key;
            result[key] = {};
            result[key].type = field.options.type;
            result[key].visible = field.options.visible ?? true;
            result[key].title = field.options.title;
            result[key].description = field.options.description;
            result[key].value = field.options.value;
            result[key].items = this._dropDownTransformer.translateToBody(field.options.items);
        }

        return result;
    }

    addItem = (id, newItem, originFields) => this._dropDownTransformer.addItem(id, newItem, originFields);
    updateItem = (id, updatedItem, originFields) => this._dropDownTransformer.updateItem(id, updatedItem, originFields);
    removeItem = (id, originFields) => this._dropDownTransformer.removeItem(id, originFields);
}