import React from 'react';
import PropTypes from 'prop-types';
import * as md from 'react-icons/md';
import Tooltip from '../Tooltip';
import './index.css';

const libs = {
    'md': md
};

class Icon extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            pressed: false
        }
    }

    updateStyle() {
        let style = {...this.props.style};

        if (this.props.behaviorAs === 'button') {

            if (this.props.disabled) {
                style.cursor = 'not-allowed';
                // style.color = '#FFF';
                // style.backgroundColor = '#b7b7b7';
                return style;
            }

            style.cursor = 'pointer';
            // style.backgroundColor = 'rgba(255, 255, 255, 0)';

            if (this.state.pressed) {
                // style.backgroundColor = 'rgba(255,235,17,0.42)';
            } else if (this.state.hover) {
                // style.backgroundColor = 'rgba(255,235,17,0.2)';
            }
        }

        return style;
    }

    handleHover = (e) => {

        // if (this.props.behaviorAs !== 'button') {
        //     if (this.state.hover) {
        //         this.setState({ hover: false });
        //     }
        //
        //     return;
        // }

        if (e.type === 'mouseenter') {
            this.setState({ hover: true });
        } else if (e.type === 'mouseleave') {
            this.setState({ hover: false });
        }
    }

    handlePress = (e) => {

        if (this.props.disabled) {
            return;
        }

        if (this.props.behaviorAs !== 'button') {
            if (this.state.hover) {
                this.setState({ pressed: false });
            }

            return;
        }

        if (e.type === 'mousedown') {
            this.setState({ pressed: true });
        } else if (e.type === 'mouseup') {
            this.setState({ pressed: false });
        }
    }

    handleClick = () => {
        if (!this.props.disabled) {
            this.props.onClick?.();
        }
    }

    updateClassName(className) {
        return className + ' ' + this.props.className;
    }

    get tooltipStyle() {
        let tooltipStyle = this.props.tooltipStyle;

        if (this.props.disabled) {
            tooltipStyle = 'disabled'
        }

        return tooltipStyle;
    }

    render() {
        let iconLib = libs[this.props.lib];
        iconLib = (iconLib) ? iconLib : libs['md'];
        let FoundIcon = iconLib[this.props.name];
        FoundIcon = (FoundIcon) ? FoundIcon : libs['md']['MdQuestionMark'];
        const style = this.updateStyle();

        return <Tooltip visible={this.props.tooltipMessage && this.state.hover}
                        tooltipStyle={this.props.tooltipStyle}
                        message={this.props.tooltipMessage}
                        align={this.props.tooltipAlign}>
            <FoundIcon className={this.updateClassName('icon-content')}
                       style={style}
                       onMouseEnter={this.handleHover}
                       onMouseLeave={this.handleHover}
                       onMouseUp={this.handlePress}
                       onMouseDown={this.handlePress}
                       onClick={this.handleClick}/>
        </Tooltip>;
    }

}

Icon.propTypes = {
    lib: PropTypes.string,
    behaviorAs: PropTypes.string,
    name: PropTypes.string.isRequired,
    tooltipMessage: PropTypes.string,
    tooltipStyle: PropTypes.string,
    style: PropTypes.object,
    tooltipAlign: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
}

Icon.defaultProps = {
    tooltipStyle: 'primitive'
}

export default Icon;