import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../IconButton';
import './index.css';

class IconASUpload extends React.Component {

    handleUpload = (e) => {
        const fr = new FileReader();
        fr.readAsText(e.target.files[0], "UTF-8");
        fr.onload = (e) => {
            const strData = e.target.result;

            try {
                const data = JSON.parse(strData);
                this.props?.onLoad(data);
            } catch (e) {
                console.log(e.message);
            }

        }
    }

    handleClick = () => {
        this._input.click();
    }

    render() {

        return (
            <div className={'icon-as-upload-container'}>
                <IconButton iconName={this.props.iconName || 'MdFileUpload'}
                            tooltipAlign={'left'}
                            tooltipInfo={this.props.tooltipInfo || 'Завантажити'}
                            onClick={this.handleClick} />
                <input ref={(input) => this._input = input}
                       style={{ width:0, height: 0 }}
                       type={'file'}
                       onChange={this.handleUpload}/>
            </div>
        );
    }
}

IconASUpload.propTypes = {
    iconName: PropTypes.string,
    tooltipInfo: PropTypes.string,
    onLoad: PropTypes.func.isRequired
}

export default IconASUpload;