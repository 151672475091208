import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Layout';
import Home from '../Home';
import AutoStepsList from '../AutoStepsProjects';
import NewAStepProject from '../NewAStepProject';
import AStepProject from '../AStepProject';
import AStepsBuilder from '../AStepsBuilder';
import AStepsViewer from '../AStepsViewer';
import UAPetitionNumber from '../UAPetitionNumber';
import NoPage from '../NoPage';
import NoProject from '../NoProject';

class Root extends React.Component {

    render() {

        return <BrowserRouter>
            <Routes>
                <Route path="/" element={<Header indent={5} />}>
                    <Route index element={<Home />} />
                    <Route path="/auto-steps/builder" element={<AStepsBuilder />} />
                    <Route path="/auto-steps/projects" element={<AutoStepsList />} />
                    <Route path="/auto-steps/projects/view/*" element={<AStepsViewer />} />
                    <Route path="/auto-steps/projects/new" element={<NewAStepProject />} />
                    <Route path="/auto-steps/projects/*" element={<AStepProject />} />
                    <Route path="/auto-steps/ua-petitions/numbers" element={<UAPetitionNumber />} />
                    <Route path="/not-found-project" element={<NoProject />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    }
}

Root.propTypes = {
    title: PropTypes.string
}

export default Root;