import React from 'react';
import Row from '../Row';
import { ReactComponent as AStepsLogo } from '../../asserts/active-logo.svg';
import './index.css';


class Logo extends React.Component {

    render() {

        return <Row verticalAlign={'center'}>
            <AStepsLogo style={{ width: '60px', height: '60px' }} />
            <span className={'last-logo-content'}>teps</span>
        </Row>
    }
}

export default Logo;