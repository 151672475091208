import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../IconButton';
import './index.css';
import Button from "../Button";
import Icon from "../Icon";
import Row from "../Row";

class ASUpload extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            uploadErrorMessages: '',
            errorMessages: '',
            dirty: false
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (state.uploadErrorMessages) {
            return state;
        }

        if (!props.dirty && !state.dirty) {
            return {
                dirty: true,
                errorMessages: '',
                uploadErrorMessages: ''
            };
        }

        let errorMessages = '';

        if (props.required && !props.value) {
            errorMessages += 'Треба загрузити автоматичні кроки';
        }

        if (state.errorMessages !== errorMessages) {
            props.onValidate?.(errorMessages);
        }

        return {
            ...state,
            errorMessages
        };
    }

    validate(strData) {

        if (!strData) {
            throw new Error('Файл порожній');
        }

        let errorMessages = '';
        let data = null;

        try {
            data = JSON.parse(strData);
        } catch (e) {
            throw new Error('Недійсний JSON');
        }

        if (data) {
            const keys = Object.keys(data);

            if (keys.length === 0) {
                throw new Error('JSON об`єкт порожній');
            }

            if (!data.groups) {
                errorMessages += '- Немає поле `groups`';
            }

            if (!data.fields) {
                errorMessages += '\n';
                errorMessages += '- Немає поле `fields`';
            }
        }

        if (errorMessages) {
            throw new Error(errorMessages);
        }
    }

    handleUpload = (e) => {
        const fr = new FileReader();

        try {
            fr.readAsText(e.target.files[0], "UTF-8");
            fr.onload = (e) => {
                const strData = e.target.result;

                try {
                    this.validate(strData);
                    this.setState({dirty: true, uploadErrorMessages: ''});
                    this.props.onValidate?.('');
                    this.props.onLoad?.(strData);
                } catch (e) {
                    this.setState({
                        dirty: true,
                        uploadErrorMessages: e.message
                    });
                    this.props.onValidate?.(e.message);
                    this.props.onLoad?.('');
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    handleClick = () => {
        this._input.click();
    }

    render() {
        const { errorMessages, uploadErrorMessages } = this.state;

        return (
            <Row verticalAlign={'center'} horizontalAlign={'center'}>
                {this.props.value &&
                    <Icon style={{width: '25px', height: '25px', marginBottom: '7px'}}
                          className={'icon-add'}
                          name={'MdCheckCircleOutline'}/>
                }
                {(uploadErrorMessages || errorMessages) &&
                <Icon style={{width: '25px', height: '25px', marginBottom: '7px'}}
                      tooltipAlign={'left'}
                      className={'icon-error'}
                      name={'MdErrorOutline'}
                      tooltipStyle={'error'}
                      tooltipMessage={uploadErrorMessages || errorMessages}/>
                }
                <Button name={'Завантажити JSON'}
                        style={{ border: 'none', backgroundColor: 'transparent' }}
                        onClick={this.handleClick} />
                <input ref={(input) => this._input = input}
                       accept="application/JSON"
                       style={{ visibility: 'hidden' }}
                       type={'file'}
                       onChange={this.handleUpload} />
            </Row>
        );
    }
}

ASUpload.propTypes = {
    dirty: PropTypes.bool,
    iconName: PropTypes.string,
    tooltipInfo: PropTypes.string,
    onLoad: PropTypes.func.isRequired
}

export default ASUpload;