import React from 'react';
import Select from "../../../components/Select";
import FieldSelect from "../FieldSelect";

class LoopContent extends React.Component {

    static options = [
        {
            value: 'none',
            title: 'Нічого не вибрано'
        }
    ]

    constructor(props) {
        super(props);

        this.state = {
            value: null,
            args: props.args ?? null
        }
    }

    static getDerivedStateFromProps(nextProps) {
        const options = [...FieldSelect.options];

        nextProps.fields?.forEach((field) => {
            const { type, key } = field.options;

            if (type === 'array') {
                if (key) {
                    options.push({ value: key, title: key });
                }
            }
        });

        return { options };
    }

    handleUpdate = (value) => {
        const { id } = this.props.group;
        const group = { ...this.props.group.options, loopKey: value };

        this.props.onChange?.('group', 'update',
            { id, group });
    }

    render() {
        const { group } = this.props;
        const loopKey = group.options.loopKey || 'none';

        return  <Select defaultValue={'none'}
                    label={'Цикл'}
                    value={loopKey}
                    options={this.state.options}
                    onChange={this.handleUpdate} />
    }

}

export default LoopContent;