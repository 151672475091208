import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import './index.css';
import Row from "../../../components/Row";
import IconButton from "../../../components/IconButton";
import LoopContent from "../LoopContent";
import Step from "./Step";
import PropTypes from "prop-types";
import Content from "../../../components/Content";
import Space from "../../../components/Space";

class Group extends React.Component {

    static createGroup() {
        return {
            id: uuidv4(),
            options: {
                loopKey: 'none',
                condition: null,
                steps: []
            }
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            group: props.group
        }
    }

    handleAddGroup = (args) => {
        const group = Group.createGroup();

        this.handleChange('group', 'add', {
            id: args.groupId, group
        });
    }

    handleRemoveGroup = (args) => {
        this.handleChange('group', 'remove',
            {id: args.groupId});
    }

    handleAddStep = (args) => {
        let step = Step.createStep();
        this.handleChange('step', 'addFirst',
            {groupId: args.groupId, step});
    }

    handleChange = (item, action, result) => {
        this.props.onChange?.(item, action, result);
    }

    get groupHeader() {
        return (
            <Row verticalAlign={'center'}
                 horizontalAlign={'center'} >
                <div style={{ width: '200px' }}>
                    <LoopContent
                        group={this.props.group}
                        fields={this.props.fields}
                        onChange={this.props.onChange}/>
                </div>
                {this.props.editable &&
                    <>
                        <Space wSize={'5px'} />
                        <IconButton args={{groupId: this.props.group.id}}
                                    iconName={'MdOutlineAdd'}
                                    buttonStyle={'add'}
                                    tooltipAlign={'left'}
                                    tooltipInfo={'Додати групу'}
                                    onClick={this.handleAddGroup}/>
                        <Space wSize={'5px'} />
                        <IconButton args={{groupId: this.props.group.id}}
                                    iconName={'MdOutlineRemove'}
                                    buttonStyle={'remove'}
                                    tooltipAlign={'left'}
                                    tooltipInfo={'Видалити групу'}
                                    onClick={this.handleRemoveGroup}/>
                    </>
                }
            </Row>
        );
    }

    get stepsHeader() {

        if (!this.props.editable) {
            return null;
        }

        return (
            <IconButton args={{groupId: this.props.group.id}}
                        iconName={'MdOutlineAdd'}
                        buttonStyle={'add'}
                        tooltipAlign={'left'}
                        tooltipInfo={'Додати крок'}
                        onClick={this.handleAddStep}/>
        );
    }

    get stepsContainer() {
        const { steps } = this.props.group.options;

        if (steps.length === 0) { return null }

        return steps.map((step) =>
            <Step key={step.id}
                  editable={this.props.editable}
                  step={step}
                  onChange={this.props.onChange}/>)
    }

    render() {
        return <div className={'group-container'}>
            <Content title={'Група #' + (this.props.index + 1)}
                     titleSize={14}
                     indent={2}
                     headerElement={this.groupHeader}>
                <div className={'step-container'}>
                    <Content title={'Кроки'}
                             titleSize={14}
                             indent={2}
                             headerElement={this.stepsHeader}>
                        {this.stepsContainer}
                    </Content>
                </div>
            </Content>
        </div>
    }
}

Group.propTypes = {
    index: PropTypes.number,
    editable: PropTypes.bool
}

Group.defaultProps = {
    index: 0,
    editable: true
}

export default Group;