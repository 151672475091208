export default class JSONFile {

    static write(name, data) {
        const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`

        return new Promise((resolve, reject) => {
            let link = document.createElement('a');
            link.href = jsonString;
            link.download = name.includes('.json') ? name : name + '.json';

            link.onload = () => resolve(`${name} file loaded successfully`);
            link.onerror = () => reject(new Error('Unable to save file: ' + name));

            link.click();
        })
    }
}