import React from 'react';
import PropTypes from 'prop-types';
import FormItem from './FormItem';
import Button from '../Button';
import Row from '../Row';
import Space from '../Space';
import './index.css';

class Form extends React.Component {

    handleChangeFormItem = (id, value) => {
        const fromItems = this.props.fromItems.map((fromItem) => {
            if (fromItem.id === id) {
                fromItem.value = value;
            }

            return fromItem;
        })

        this.setState({ fromItems });

        this.props.onChange?.(this.props.fromItems.find((fromItem) => fromItem.id === id));
    }

    handleValidateFormItem = (id, errorMessages) => {
        const fromItems = this.props.fromItems.map((fromItem) => {
            if (fromItem.id === id) {
                fromItem.hasError = !!errorMessages;
            }

            return fromItem;
        });

        this.setState({ fromItems });
    }

    handleClickOk = () => {
        let hasError = false;

        for (let fromItem of this.props.fromItems) {
            if (fromItem.required && fromItem.value.length === 0) {
                hasError = true;
                break;
            }
        }

        if (hasError) {
            const fromItems = this.props.fromItems.map((fromItem) => {
                fromItem.dirty = true;
                return fromItem;
            });

            this.setState({ fromItems });
        } else {
            this.props.onClickOk?.();
        }
    }

    get hasErrors() {
        return !!this.props.fromItems.find((fromItem) => fromItem.hasError);
    }

    render() {
        return <div>
            {this.props.fromItems.map((item) => (
                <div key={item.id}>
                    <FormItem id={item.id}
                              inputType={item.inputType}
                              dirty={item.dirty}
                              required={item.required}
                              justLatin={item.justLatin}
                              title={item.title}
                              descriptionContent={item.descriptionContent}
                              description={item.description}
                              unique={item.unique}
                              options={item.options}
                              value={item.value}
                              maxSize={item.maxSize}
                              onChange={this.handleChangeFormItem}
                              onValidate={this.handleValidateFormItem}/>
                </div>
            ))}
            {this.props.children}
            <Space hSize={'20px'} />
            <Row horizontalAlign={'right'}>
                <Button name={this.props.okButtonTitle}
                        disabled={this.hasErrors}
                        onClick={this.handleClickOk} />
                <Space wSize={'20px'} />
                {this.props.onClickCancel &&
                    <Button name={this.props.cancelButtonTitle}
                            onClick={this.props.onClickCancel} />
                }
                <Space wSize={'5%'} />
            </Row>
        </div>
    }
}

Form.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    okButtonTitle: PropTypes.string,
    cancelButtonTitle: PropTypes.string,
    onChange: PropTypes.func,
    onClickOk: PropTypes.func,
    onClickCancel: PropTypes.func
}

Form.defaultProps = {
    okButtonTitle: 'Ок',
    cancelButtonTitle: 'Скасувати'
}

export default Form;