import React from 'react';
import PropTypes from 'prop-types';
import UIFieldValidation from './validation';
import Row from "../../components/Row";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Space from "../../components/Space";
import Description from "../../components/Description";
import Column from "../../components/Column";
import './index.css';
import Header from "../../components/Header";

class UserInterface extends React.Component {

    constructor(props) {
        super(props);
        this.validation = new UIFieldValidation();
    }

    handleChangeValue = (value, args) => this.handleUpdate({ value }, args)

    handleUpdate = (value, args) => {

        if (!args || !args.fieldId) {
            console.log('Expected value: fieldId');
            console.log(args);
            return;
        }

        const id = args.fieldId;
        const field = this.props.fields.find((field) => field.id === id);

        if (field) {
            const fieldOptions = {...field.options, ...value};
            this.props.onChange?.('field', 'update', { id, fieldOptions });
        } else {
            this.log.warn('Unable update value, no field with id: ' + id);
        }
    }

    filterItems = () => {
        const fields = this.props.fields.filter((field) => field.options.visible);

        fields.map((field) => {
            if (field.options.type === 'dropdown') {
                // field.options.items = field.options.items.filter((item) => item.value && item.title);
            }

            return field;
        });

        return fields;
    }


    render() {
        const items = this.filterItems();

        return <div className={'ui-container'}>
            {items.length !== 0 &&
                <Header titleSize={17} title={'Інтерфейс користувача'} />
            }
            <div className={'ui-content'}>
                {items.map((field) => (
                        <Row key={field.id}>
                            <Column size={6}>
                                <Description title={field.options.title}
                                             description={field.options.description}/>
                            </Column>
                            <Column size={6}>
                                <Space hSize={'10px'} />
                                <Row horizontalAlign={'center'} verticalAlign={'center'}>
                                {field.options.visible && field.options.type === 'array' &&
                                    <Input containerStyle={{ marginLeft: '10%', width: '70%' }}
                                           args={{fieldId: field.id}}
                                           label={field.options.title}
                                           value={field.options.value}
                                           errorMassage={this.validation.validateArrayData(field.options)}
                                           onChange={this.handleChangeValue}/>
                                }
                                    {field.options.visible && field.options.type === 'input' &&
                                        <Input containerStyle={{ marginLeft: '10%', width: '70%' }}
                                               args={{fieldId: field.id}}
                                               label={field.options.title}
                                               value={field.options.value}
                                               errorMassage={this.validation.validateArrayData(field.options)}
                                               onChange={this.handleChangeValue}/>
                                    }
                                {field.options.visible && field.options.type === 'dropdown' &&
                                    <Select containerStyle={{ marginLeft: '10%', width: '70%' }}
                                            args={{fieldId: field.id}}
                                            label={field.options.title}
                                            value={field.options.value}
                                            defaultValue={'none'}
                                            options={field.options.items}
                                            onChange={this.handleChangeValue}/>
                                }
                                </Row>
                            </Column>
                        </Row>
                ))}
            </div>
        </div>
    }
}

UserInterface.propTypes = {
    automationProgress: PropTypes.string,
    fields: PropTypes.array.isRequired,
    onChange: PropTypes.func
}

export default UserInterface;