import React from 'react';
import Logger from "../../units/logger";

class AppComponent extends React.Component {

    constructor(className, props, requiredProps = []) {
        super(props);
        this.log = new Logger(className);

        let required = null;
        for (let requiredProp of requiredProps) {

            if (!props[requiredProp]) {
                required = (required) ? `${required}, ${requiredProp}` : requiredProp;
            }
        }

        if (required) {
            throw new SyntaxError(`${className} > Required props: '${required}`);
        }
    }
}

export default AppComponent;