import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Form from '../../components/Form';
import FormItem from '../../components/Form/FormItem';
import AStepsProjectDB from '../../units/db/asteps-project-db';
import IconButton from "../../components/IconButton";

class NewAStepProject extends React.Component {

    constructor(props) {
        super(props);

        let fromItems= [];

        fromItems[0] = FormItem.createInput({
            required: true,
            justLatin: true,
            title: 'Шлях проєкту',
            descriptionContent: (<div>
                Введіть унікальний шлях проєкту.<br/>
                Підтримує тільки латиницю та <b style={{ color: 'black' }}>- _</b><br/>
                Наприклад: <i>ua-petitions-bank</i>
            </div>),
            unique: [],
            value: '',
            maxSize: 20
        });

        fromItems[1] = FormItem.createInput({
            required: true,
            title: 'Назва проєкту',
            description: "Введіть назву проєкту",
            value: '',
            maxSize: 50
        });

        fromItems[2] = FormItem.createTextArea({
            required: true,
            title: 'Опис проєкту',
            description: "Введіть опис проєкту",
            value: '',
            maxSize: 225
        });


        fromItems[3] = FormItem.createUploader({
            required: true,
            title: 'Як зберегти автоматичні кроки в JSON',
            descriptionContent: (<div>
                <div>1. Відкрити ASteps розширення</div>
                <div>2. Створити автоматичні кроки для сайту</div>
                <div>3. Зберегти ваші кроки в форматі *.json на PC</div>
                <div>4. Завантажити JSON</div>
            </div>),
            value: ''
        });

        this.state = {
            fromItems
        }

        AStepsProjectDB.addUpdateAStepsProjects(this.updateUniqueAStepsProjects);
    }

    updateUniqueAStepsProjects = (projects) => {
        const projectPaths = projects.map((project) => project.projectPath);
        const fromItems = this.state.fromItems.map((fromItem, index) => {
            if (index === 0) {
                fromItem.unique = projectPaths;
            }

            return fromItem;
        });

        this.setState({ fromItems });
    }

    componentDidMount() {
        AStepsProjectDB.update();
    }

    handleClickSave = () => {
        const { fromItems } = this.state;
        const projectPath = fromItems[0].value;
        const title = fromItems[1].value;
        const description = fromItems[2].value;
        const autoSteps = fromItems[3].value;
        AStepsProjectDB.save(projectPath, title, description, autoSteps);
        this.props.navigate('/auto-steps/projects');
    }

    handleClickCancel = () => {
        this.props.navigate('/auto-steps/projects');
    }

    handleNavToBuilder = () => {
        this.props.navigate('/auto-steps/builder');
    }

    render() {

        return <>
            <Header indent={5} title={'Створити ASteps проєкт'} />
            <Form fromItems={this.state.fromItems}
                  okButtonTitle={'Зберегти'}
                  onClickOk={this.handleClickSave}
                  onClickCancel={this.handleClickCancel} />
        </>
    }
}

NewAStepProject.propTypes = {

}

export default () => {
    const navigate = useNavigate();
    return (
        <NewAStepProject navigate={navigate} />
    )
};