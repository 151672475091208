import React from 'react';
import KiwiBrowserLink from '../../pages/Home/KiwiBrowserLink';
import Description from '../../components/Description';
import Link from '../../components/Link';
import Column from '../../components/Column';
import Icon from '../../components/Icon';
import Row from "../../components/Row";
import './index.css';
import Utils from "../../units/utils";

class NoAddonMessage extends React.Component {

    render() {

        return <Row className={'no-addon-container'} verticalAlign={'center'}>
            <Column size={1} style={{ alignItems: 'center' }}>
                <Icon className={'no-addon-icon'} style={{ width: '30px', height: '30px' }} name={'MdOutlineWarningAmber'} />
            </Column>
            <Column size={10}>
                <Description descriptionContent={
                    <div className={'no-addon-text'}>
                        {this.props.isMobile &&
                            <>
                                <i style={{fontSize: '15px'}}>
                                    <span>Встановіть </span>
                                    <Link title={'Kiwi Browser'}
                                          url={KiwiBrowserLink.URL}/>
                                    <span>!</span>
                                    <span> Воно підтримує <b>add-ons</b> для <Icon style={{color: '#856404'}} name={'MdSecurityUpdate'}/>.</span>
                                </i>
                                <br/>
                            </>
                }
                <span style={{fontSize: '16px'}}> Треба встановити чи увімкнути розширення: </span>
                        <Link title={'ASteps'}
                              url={'https://chromewebstore.google.com/detail/asteps/fcamdjkepffocnmlpdjjpelbjakngnah'}/>
                        <br/>
                        <i style={{fontSize: '15px'}}>Перезавантажте сторінку після активації розширення.</i>
                    </div>
                }/>
            </Column>
            <Column size={1}/>
        </Row>
    }
}

export default () => {
    const isMobile = Utils.useCheckMobileScreen();

    return <NoAddonMessage isMobile={isMobile} />
}