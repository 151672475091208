import PetitionDB from '../db/petition-db';

export default class PetitionSorter {

    static #instance = null;

    static passed = {};

    static notPassed = {};

    static done = {};

    static skipped = {};

    constructor() {

        if (PetitionSorter.#instance) {
            return PetitionSorter.#instance;
        }

        PetitionSorter.#instance = this;
        this.#init();
        return this;
    }

    savePetitionIds(category) {
        const key = category ? 'petitionIds/' + category : 'petitionIds';

        localStorage.setItem(key, JSON.stringify({
            passed: PetitionSorter.passed,
            done: PetitionSorter.done,
            skipped: PetitionSorter.skipped
        }));
    }

    getPetitionIds(category) {
        const key = category ? 'petitionIds/' + category : 'petitionIds';
        return JSON.parse(localStorage.getItem(key));
    }

    #init = () => {
        const petitionIds = this.getPetitionIds();

        if (petitionIds) {
            PetitionSorter.passed = petitionIds.passed;
            PetitionSorter.done = petitionIds.done;
            PetitionSorter.skipped = petitionIds.skipped;
        }

        PetitionDB.addUpdatePetitionIds((petitionIds) => {
            console.log('PetitionDB.addUpdatePetitionIds');
            console.log(petitionIds);
            PetitionSorter.notPassed = {};

            this.updatePetitionIdsStorage(petitionIds);

            for (let petitionId in petitionIds) {

                if (!PetitionSorter.passed[petitionId]
                    && !PetitionSorter.done[petitionId]
                    && !PetitionSorter.skipped[petitionId])
                {
                    PetitionSorter.notPassed[petitionId] = true;
                }
            }

            this.updatePetitionIdStatus();
        });
    }

    restorePetitionIdStorage(category, param) {
        if (PetitionSorter[param] && param !== 'notPassed') {
            PetitionSorter.notPassed = {...PetitionSorter[param]};
            PetitionSorter[param] = {};
            this.savePetitionIds(category);
        }
    }

    updatePetitionIdsStorage(petitionIds) {
        PetitionSorter.passed = this.filterPetitionIds('passed', petitionIds);
        PetitionSorter.done = this.filterPetitionIds('done', petitionIds);
        PetitionSorter.skipped = this.filterPetitionIds('skipped', petitionIds);
        PetitionSorter.notPassed = this.filterPetitionIds('notPassed', petitionIds);
        this.savePetitionIds();
    }

    filterPetitionIds(key, petitionIds) {

        if (!PetitionSorter[key]) {
            console.log(`Not found PetitionSorter[${key}]`);
            return {};
        }

        const result = {}

        for (let petitionId in PetitionSorter[key]) {
            if (petitionIds[petitionId]) {
                result[petitionId] = {};
            }
        }

        return result;
    }

    set handlerUpdatePetitionIdStatus(handler) {

        if (!this._handlerUpdatePetitionIdStatusList) {
            this._handlerUpdatePetitionIdStatusList = [];
        }

        this._handlerUpdatePetitionIdStatusList.push(handler);
    }

    updatePetitionIdStatus() {
        for (let handlerUpdatePetitionIdStatus of this._handlerUpdatePetitionIdStatusList) {
            handlerUpdatePetitionIdStatus?.(
                PetitionSorter.notPassed,
                PetitionSorter.passed,
                PetitionSorter.done,
                PetitionSorter.skipped
            );
        }
    }

    setAsPassed(petitionId) {
        if (petitionId && !isNaN(+petitionId) && PetitionSorter.notPassed[petitionId]) {
            PetitionSorter.passed[petitionId] = true;
            delete PetitionSorter.notPassed[petitionId];
            console.log('Changed status to passed, petitionId: ' + petitionId);
            this.updatePetitionIdStatus();
        } else {
            console.log('Try change status to passed, petitionId: ' + petitionId);
        }
    }

    setAsDone(petitionId) {
        if (petitionId && !isNaN(+petitionId) && PetitionSorter.notPassed[petitionId]) {
            PetitionSorter.done[petitionId] = true;
            delete PetitionSorter.notPassed[petitionId];
            console.log('Changed status to done, petitionId: ' + petitionId);
            this.updatePetitionIdStatus();
        } else {
            console.log('Try change status to done, petitionId: ' + petitionId);
        }
    }

    setAsSkipped(petitionId) {
        if (petitionId && !isNaN(+petitionId) && PetitionSorter.notPassed[petitionId]) {
            PetitionSorter.skipped[petitionId] = true;
            delete PetitionSorter.notPassed[petitionId];
            console.log('Changed status to skipped, petitionId: ' + petitionId);
            this.updatePetitionIdStatus();
        } else {
            console.log('Try change status to skipped, petitionId: ' + petitionId);
        }
    }

    static addUpdatePetitionIdStatus(handler) {
        new PetitionSorter().handlerUpdatePetitionIdStatus = handler;
    }

    static clear(category, param) {
        const petitionSorter = new PetitionSorter();
        petitionSorter.restorePetitionIdStorage(category, param);
        petitionSorter.updatePetitionIdStatus();
    }

    static update(data) {
        const petitionSorter = new PetitionSorter();

        if (data.progress === 'inProgress') {
            if (data.status === 'passed') {
                petitionSorter.setAsPassed(data.petitionId);
            } else if (data.status === 'done') {
                petitionSorter.setAsDone(data.petitionId);
            } else if (data.status === 'skipped') {
                petitionSorter.setAsSkipped(data.petitionId);
            }
        } else if (data.progress === 'finished') {
            petitionSorter.savePetitionIds();
        }
    }
}