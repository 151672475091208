import React from 'react';
import PropTypes from 'prop-types';
import Row from '../Row';
import Space from '../Space';
import './index.css';

class Header extends React.Component {

    get style() {
        const { indent } = this.props;
        const style = { ...this.props.style };

        style.width = (100 - indent * 2) + '%';
        style.marginLeft = indent + '%';

        return style;
    }

    render() {
        return <Row style={this.style}
                    className={ this.props.visibleUnderscore ? 'header-underscore' : '' }
                    verticalAlign={'center'}>
            <Row verticalAlign={'center'}
                 className={'header-title'}
                 style={{ padding: this.props.paddingHeader, fontSize: this.props.titleSize }}>

                {this.props.preTitleElement}
                {this.props.preTitleElement && <Space wSize={'10px'} />}
                <span>{this.props.title}</span>
            </Row>
            <div className={'header-right'} >
                {this.props.children}
            </div>
        </Row>
    }
}

Header.propTypes = {
    visibleUnderscore: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    indent: PropTypes.number,
    preTitleElement: PropTypes.element,
    title: PropTypes.string.isRequired,
    titleSize: PropTypes.number,
    paddingHeader: PropTypes.string
}

Header.defaultProps = {
    visibleUnderscore: true,
    className: '',
    style: {},
    indent: 0,
    titleSize: 25,
    paddingHeader: '15px 15px 15px 15px'
}

export default Header;