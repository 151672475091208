import React from 'react';
import InfoLine from './InfoLine';
import Row from '../Row';
import './index.css';
import PropTypes from "prop-types";

class Info extends React.Component {

    render() {

        return <div>
            <Row className={'info-title'} verticalAlign={'center'} >
                <span>{this.props.title}</span>
            </Row>
            {this.props.lines.map((text, index) => <InfoLine key={index} text={text} />)}
        </div>
    }
}

Info.propTypes = {
    title: PropTypes.string.isRequired,
    lines: PropTypes.array
}

Info.defaultProps = {
    lines: []
}

export default Info;