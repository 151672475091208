import React from 'react';
import Root from './pages/Root';

class App extends React.Component {

	render() {
		return (<Root />);
	}
}

export default App;