import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

class Row extends React.Component {

    updateStyle = () => {
        const style = { ...this.props.style };

        if (this.props.behaviorAs === 'button') {
            style.userSelect = 'none';
            style.cursor = 'pointer';
        }

        if (this.props.maxHeight?.includes('px')) {
            let height = this.props.maxHeight;
            style.maxHeight = height;
            style.height = height;
            style.transitionProperty = 'height maxHeight';
            style.transitionDuration = '2s';
        }

        if (this.props.horizontalAlign === 'center'
            || this.props.horizontalAlign === 'right'
            || this.props.horizontalAlign === 'left') {
            style.justifyContent = this.props.horizontalAlign;
        }

        if (this.props.verticalAlign === 'center') {
            style.alignItems = 'center';
        }

        if (this.props.scroll) {
            style.overflow = 'auto';
        }

        return style;
    }

    get className() {
        let style = 'row-container';

        if (this.props.className) {
            style += ' ' + this.props.className;
        }

        return style;
    }

    render() {
        return <div className={this.className}
                    style={this.updateStyle()}
                    onClick={this.props.onClick}>
            {this.props.children}
        </div>
    }

}

Row.propTypes = {
    behaviorAs: PropTypes.string,
    horizontalAlign: PropTypes.string,
    verticalAlign: PropTypes.string,
    scroll: PropTypes.bool,

    height: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func
}

Row.defaultProps = {
    maxHeight: null
}

export default Row;