import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import './index.css';
import Duration from "../../units/duration";

class Tooltip extends React.Component {

    static tooltipContent = {
        'primitive': 'primitive-tooltip',
        'error': 'error-tooltip',
        'disabled': 'disabled-tooltip'
    }

    static tooltipMessage = {
        'primitive': 'primitive-tooltip-message',
        'error': 'error-tooltip-message',
        'disabled': 'disabled-tooltip-message'
    }

    constructor(props) {
        super(props);
        this.state = {
            expired: false
        }
    }

    updateStyle() {
        const style = {};

        if (this.state.expired) {
            style.display = 'none';
        } else if (this.props.visible) {
            style.display = 'inline-block';
        }

        style.top = '50%';
        style.transform = 'translateY(-50%)';
        if (this.props.align === 'right') {
            style.left = '110%';
            style.right = 'none';
        } else if (this.props.align === 'left') {
            style.left = 'none';
            style.right = '110%';
        } else {
            // default right
            style.left = '110%';
            style.right = 'none';
        }

        return style;
    }

    get tooltipContent() {
        let classNames = 'tooltip-content';
        classNames += ' ' + Tooltip.tooltipContent[this.props.tooltipStyle || 'primitive']
            || Tooltip.tooltipContent['primitive'];

        if (this.props.align === 'left') {
            classNames += ' right-tooltip-content'
        }

        return classNames;
    }

    get tooltipMessage() {
        let classNames = 'tooltip-message';
        classNames += ' ' + Tooltip.tooltipMessage[this.props.tooltipStyle || 'primitive']
            || Tooltip.tooltipContent['primitive'];

        return classNames;
    }

    checkTimer() {
        if (this.props.visible && !this.duration) {
            this.duration = this.setDuration(5000)
                .then(() => this.setState({ expired: true }));
        }

        if (!this.props.visible && this.duration) {
            this.duration = null;
            this.clearDuration();
            this.setState({ expired: false });
        }
    }

    componentDidUpdate() {
        this.checkTimer();
    }

    render() {

        return <div className={'tooltip-element'}>
            {this.props.children}
            <div className={'tooltip-container'}
                 style={this.updateStyle()}>
                <div className={this.tooltipContent}>
                    <span className={this.tooltipMessage}>
                        {this.props.message}
                    </span>
                </div>
            </div>
        </div>
    }

}

Object.assign(Tooltip.prototype, Duration);

Tooltip.propTypes = {
    visible: PropTypes.bool,
    tooltipStyle: PropTypes.string,
    message: PropTypes.string,
    style: PropTypes.object,
    align: PropTypes.string
}

Tooltip.defaultProps = {
    tooltipStyle: 'primitive'
}

export default Tooltip;