import { v4 as uuidv4 } from 'uuid';

class StepListTransformer {

    addFirstStep(groupId, newStep, originGroups) {
        const copiedGroups = structuredClone(originGroups);

        for (let group of copiedGroups) {
            let steps = group.options.steps;

            if (group.id === groupId) {
                steps.splice(0, -1, newStep);
            }
        }

        return copiedGroups;
    }

    addStep(id, newStep, originGroups) {
        const copiedGroups = structuredClone(originGroups);
        this.#findStep(id, copiedGroups,
            (step, index, steps) => { steps.splice(index + 1, -1, newStep) });
        return copiedGroups;
    }

    updateStep(id, stepOptions, originGroups) {
        const copiedGroups = structuredClone(originGroups);
        this.#findStep(id, copiedGroups,
            (step) => { step.options = stepOptions });
        return copiedGroups;
    }

    removeStep(id, originGroups) {
        const copiedGroups = structuredClone(originGroups);
        this.#findStep(id, copiedGroups,
            (step, index, steps) => { steps.splice(index, 1) });
        return copiedGroups;
    }

    #findStep(id, groups, callback) {
        out: for (let group of groups) {
            let index = 0;
            let steps = group.options.steps;

            if (id === -1) {
                callback(steps[0] || null, -1, steps);
                return;
            }

            for (let step of steps) {
                if (step.id === id) {
                    callback(step, index, steps);
                    break out;
                }
                index++;
            }
        }
    }

    transformToForm(groupSteps) {
        const steps = [];

        for (let step of groupSteps) {
            const s = {};
            s.id = uuidv4();
            s.options = {};
            s.options = { ...step };
            steps.push(s);
        }

        return steps;
    }

    translateToBody(groupSteps) {
        const steps = [];

        for (let step of groupSteps) {
            steps.push({ ...step.options });
        }

        return steps;
    }
}

export default class GroupListTransformer {

    constructor() {
        this._stepListTransformer = new StepListTransformer();
    }

    addGroup(id, newGroup, originGroups) {
        const copiedGroups = structuredClone(originGroups);
        this.#findGroup(id, copiedGroups,
            (group, index, groups) => groups.splice(index + 1, -1, newGroup));

        return copiedGroups;
    }

    updateGroup(id, groupOptions, originGroups) {
        const copiedGroups = structuredClone(originGroups);
        this.#findGroup(id, copiedGroups, (group) => {
            if (group) {
                group.options = groupOptions
            } else {
                console.log('Unable to update the options, group is null');
            }
        });
        return copiedGroups;
    }

    removeGroup(id, originGroups) {
        const copiedGroups = structuredClone(originGroups);
        this.#findGroup(id, copiedGroups,
            (group, index, groups) => { groups.splice(index, 1) });
        return copiedGroups;
    }

    #findGroup(id, groups, callback) {
        if (id === -1) {
            callback(groups[0] || null, -1, groups);
            return;
        }

        let index = 0;
        for (let group of groups) {
            if (group.id === id) {
                callback(group, index, groups);
                break;
            }
            index++;
        }
    }

    transformToForm(groups) {
        const result = [];

        for (let group of groups) {
            const g = {};
            g.id = uuidv4();
            g.options = {};
            g.options.loopKey = group.loopKey;
            g.options.steps = this._stepListTransformer.transformToForm(group.steps);
            result.push(g);
        }

        return result;
    }

    translateToBody(groups) {
        const result = [];

        for (let group of groups) {
            const g = {};
            g.loopKey = group.options.loopKey;
            g.steps = this._stepListTransformer.translateToBody(group.options.steps);
            result.push(g);
        }

        return result;
    }

    addFirstStep = (groupId, newStep, originGroups) => this._stepListTransformer.addFirstStep(groupId, newStep, originGroups);
    addStep = (id, newStep, originGroups) => this._stepListTransformer.addStep(id, newStep, originGroups);
    updateStep = (id, stepOptions, originGroups) => this._stepListTransformer.updateStep(id, stepOptions, originGroups);
    removeStep = (id, originGroups) => this._stepListTransformer.removeStep(id, originGroups);
}