import * as firebase from 'firebase/app';
import { getDatabase, ref, set, onValue } from 'firebase/database';
import FirebaseOptions from './firebase-options';

export default class PetitionDB {

    static petitionIds = {};

    static #instance = null;

    firebaseConfig = {
        apiKey: "AIzaSyBL3coPtjyHtXaSqM0kRO6NkTaSs0AAi4o",
        authDomain: "pretisionnumbersua.firebaseapp.com",
        databaseURL: "https://pretisionnumbersua-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "pretisionnumbersua",
        storageBucket: "pretisionnumbersua.appspot.com",
        messagingSenderId: "76217755382",
        appId: "1:76217755382:web:f92497867de6dd93a16ed7",
        measurementId: "G-40J494VE6E"
    };

    constructor() {

        if (PetitionDB.#instance) {
            return PetitionDB.#instance;
        }

        PetitionDB.#instance = this;
        return this;
    }

    get db() {
        return this._db;
    }

    static getInstance() {
        if (PetitionDB.#instance) {
            return PetitionDB.#instance;
        } else {
            return new PetitionDB();
        }
    }

    async connect() {
        let app = null;
        let apps = firebase.getApps();

        if (apps.length !== 0) {
            app = await firebase.deleteApp(apps[0]).then(() => {
                return firebase.initializeApp(FirebaseOptions.petitionConfig);
            });
        } else {
            app = firebase.initializeApp(FirebaseOptions.petitionConfig);
        }

        return getDatabase(app);
    }

    disconnect() {
        let apps = firebase.getApps();

        if (apps.length !== 0) {
            return firebase.deleteApp(apps[0]);
        }
    }

    set handlerUpdatePetitionIds(handler) {

        if (!this._handlerUpdatePetitionIds) {
            this._handlerUpdatePetitionIds = [];
        }

        this._handlerUpdatePetitionIds.push(handler);
    }

    get handlerUpdatePetitionIds() {
        return this._handlerUpdatePetitionIds;
    }

    static addUpdatePetitionIds(handler) {
        new PetitionDB().handlerUpdatePetitionIds = handler;
    }

    static save(category, petitionId) {
        return PetitionDB.set((db) => {
            let petitionsRef = ref(db, 'petitions/' + category + '/' + petitionId);
            return set(petitionsRef, { invalidCount: 0 });
        }, 5000);
    }

    static set(callback, timeout) {
        return new Promise(async (resolve, reject) => {
            const timer = setTimeout(() => reject('timeout'), timeout);
            const db = await PetitionDB.getInstance().connect();

            callback(db).then(() => {
                clearTimeout(timer);
                resolve('ok');
            })
                .catch(() => reject('failure'))
                .finally(PetitionDB.getInstance().disconnect);
        });
    }

    /*
    * Завантажує petition ids з DB
    * Потім відновлює список
    * */
    static update(category) {
        PetitionDB.getAll(category).then((petitionIds) => {
            PetitionDB.getInstance()
                .handlerUpdatePetitionIds
                ?.forEach((handler) => handler(petitionIds))
        })
    }

    static getAll(category) {

        return new Promise(async (resolve, reject) => {
            const timer = setTimeout(() => reject('timeout'), 30000);
            const db = await PetitionDB.getInstance().connect();
            const petitionsRef = ref(db,'petitions/' + category);

            onValue(petitionsRef,(snapshot) => {
                clearTimeout(timer);

                PetitionDB.petitionIds = {};
                snapshot.forEach((childSnapshot) => {
                    const petitionId = childSnapshot.key;
                    // const childData = childSnapshot.val();
                    PetitionDB.petitionIds[petitionId] = {};
                    // PetitionDB.petitionIds.push(petitionId);
                });

                resolve(PetitionDB.petitionIds);
            });
        })
    }

}