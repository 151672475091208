import React from 'react';
import PropTypes, {bool} from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Row from "../../../components/Row";
import Space from "../../../components/Space";
import IconButton from "../../../components/IconButton";
import TypeSelect from "./TypeSelect";
import Input from "../../../components/Input";
import DropDownItem from "./DropDownItem";
import Column from "../../../components/Column";
import TextArea from "../../../components/TextArea";
import './index.css';


class Field extends React.Component {

    static textAreaStyle = {
        height: '100px',
        minHeight: '20px',
        maxHeight: '100px',
        minWidth: '88%',
        maxWidth: '88%'
    }

    static createField() {
        return {
            id: uuidv4(),
            options: {
                type: 'array',
                visible: true,
                title: '',
                key: '',
                value: '',
                items: [
                    { ...DropDownItem.createFirstItem() }
                ]
            }
        }
    }

    constructor(props) {
        super(props);

        let items = props.field?.options.items;

        if (items && items.length !== 0) {
            let item = items.find((item) => item.value === 'none')

            if (!item) {
                const dropdown = { ...DropDownItem.createFirstItem() };
                this.handleChange('field-dropdown', 'add',
                    {id: -1, dropdown });
            }
        }
    }

    handleVisible = (args) => this.handleUpdate({ visible: !args.visible })
    handleSelectType = (type) => this.handleUpdate({ type })
    handleChangeKey = (key) => this.handleUpdate({ key })
    handleChangeTitle = (title) => this.handleUpdate({ title })
    handleChangeDescription = (description) => this.handleUpdate({ description })

    handleValidateKey = (errorMessages) => this.handleValidate('field:key', errorMessages)
    handleValidateTitle = (errorMessages) => this.handleValidate('field:title', errorMessages)
    handleValidateDescription = (errorMessages) => this.handleValidate('field:description', errorMessages)

    handleValidate = (key, errorMessages) => {
        const { id } = this.props.field;
        this.props.onValidate?.(id + ':' + key, errorMessages)
    }

    handleAdd = () => {

        if (this.props.hasError) {
            this.props.onDirty();
            return;
        }

        const { id } = this.props.field;
        const field = Field.createField();

        this.handleChange('field', 'add',
            { id, field });
    }

    handleUpdate = (value) => {
        const { id } = this.props.field;
        const fieldOptions = { ...this.props.field.options, ...value };
        this.handleChange?.('field', 'update',
            { id, fieldOptions });
    }

    handleRemove = () => {
        this.handleValidateKey('');
        this.handleValidateTitle('');
        this.handleValidateDescription('');
        this.handleValidate('dropdown:value', '');
        this.handleValidate('dropdown:title', '');
        this.handleChange('field', 'remove',
            { id: this.props.field.id });
    }

    handleChange = (item, action, result) => {
        this.props.onChange?.(item, action, result);
    }

    getEyeTooltipInfo(options) {
        const editable = this.props.behaviorAs !== 'viewer';

        if (options.visible) {
            return editable ? 'Приховати поле' : 'Поле видиме для користувача';
        }

        return editable ? 'Показати поле' : 'Поле приховане від користувача';
    }

    getFieldElement(options) {
        return <div>
            <Row verticalAlign={'center'}>
                <Column size={1} style={{alignItems: 'center'}}>
                    <Space hSize={'5px'}/>
                    <IconButton args={{visible: options.visible}}
                                iconName={options.visible ? 'MdOutlineRemoveRedEye' : 'MdRemoveRedEye'}
                                buttonStyle={options.visible ? 'add' : 'disabled'}
                                tooltipAlign={'right'}
                                tooltipInfo={this.getEyeTooltipInfo(options)}
                                onClick={this.handleVisible}/>
                </Column>
                <Column size={this.props.behaviorAs !== 'viewer' ? 3 :4}>
                    <TypeSelect type={options.type}
                                editable={this.props.behaviorAs !== 'viewer'}
                                onChange={this.handleSelectType}/>
                </Column>
                <Column style={{alignItems: 'center'}} size={this.props.behaviorAs !== 'viewer' ? 3 :4}>
                    <Input value={options.key}
                           dirty={this.props.dirty}
                           required={true}
                           justLatin={true}
                           label={'Ключ'}
                           maxSize={20}
                           onChange={this.handleChangeKey}
                           onValidate={this.handleValidateKey} />
                </Column>
                <Column size={this.props.behaviorAs !== 'viewer' ? 3 :4} style={{alignItems: 'center'}}>
                    <Input value={options.title}
                           dirty={this.props.dirty}
                           required={true}
                           label={'Назва'}
                           maxSize={50}
                           onChange={this.handleChangeTitle}
                           onValidate={this.handleValidateTitle} />
                </Column>
                {this.props.behaviorAs === 'viewer' &&
                    <Column size={1}></Column>
                }
                {this.props.behaviorAs !== 'viewer' &&
                    <Column size={2}>
                        <Space hSize={'7px'}/>
                        <Row horizontalAlign={'center'}>
                            <IconButton iconName={'MdOutlineAdd'}
                                        buttonStyle={'add'}
                                        disabled={this.props.dirty && this.props.hasError}
                                        tooltipAlign={'left'}
                                        tooltipInfo={'Додати поле'}
                                        onClick={this.handleAdd}/>
                            <Space wSize={'5px'} />
                            <IconButton iconName={'MdOutlineRemove'}
                                        buttonStyle={'remove'}
                                        tooltipAlign={'left'}
                                        tooltipInfo={'Видалити поле'}
                                        onClick={this.handleRemove}/>
                        </Row>
                    </Column>
                }
            </Row>
            <div style={{marginTop: '20px', marginLeft: '11%', width: '78%',}}>
                <TextArea textAreaStyle={Field.textAreaStyle}
                          dirty={this.props.dirty}
                          required={true}
                          value={options.description}
                          maxSize={225}
                          tooltipAlign={'left'}
                          label={'Опис'}
                          onChange={this.handleChangeDescription}
                          onValidate={this.handleValidateDescription} />
            </div>
        </div>
    }

    render() {
        const { options } = this.props.field;

        return <div>
            <Row className={'item-field-header'} >
                <span style={{padding: '10px'}}>Поле #{this.props.index + 1}</span>
            </Row>
            {options.type !== 'dropdown' &&
                <div className={'item-field-content'}>
                    <Space hSize={'20px'} />
                    {this.getFieldElement(options, '45px')}
                </div>
            }
            {options.type === 'dropdown' &&
                <div className={'item-field-content'}>
                    <Space hSize={'20px'} />
                    <DropDownItem headerElement={this.getFieldElement(options, null)}
                                  fieldId={this.props.field.id}
                                  dirty={this.props.dirty}
                                  hasError={this.props.hasError}
                                  uiErrors={this.props.uiErrors}
                                  items={options.items}
                                  behaviorAs={this.props.behaviorAs}
                                  onChange={this.props.onChange}
                                  onValidate={this.handleValidate}
                                  onDirty={this.props.onDirty}  />
                </div>
            }
        </div>
    }
}

Field.propTypes = {
    index: PropTypes.number,
    hasError: PropTypes.bool,
    uiErrors: PropTypes.object,
    dirty: PropTypes.bool,
    behaviorAs: PropTypes.string,
    onChange: PropTypes.func,
    onValidate: PropTypes.func,
    onDirty: PropTypes.func
}

Field.defaultProps = {
    index: 0,
    dirty: false,
    behaviorAs: 'editor'
}

export default Field;