import React from 'react';
import Header from "../../components/Header";
import AddonLink from "./AddonLink";
import Space from "../../components/Space";
import Info from "../../components/Info";
import Row from "../../components/Row";
import Description from "../../components/Description";
import KiwiBrowserLink from "./KiwiBrowserLink";
import Utils from "../../units/utils";

class Home extends React.Component {

    render() {

        return <div>
            <Header indent={5} title={'Автоматизація сайтів'} />
            <Space hSize={'10px'} />
            <Row>
                <Description title={'Доброго дня, користувач!'}
                             titleSize={'18px'}
                             descriptionSize={'17px'}
                             descriptionContent={<div>
                                 Якщо Ви шукаєте спосіб полегшити Вашу роботу, яку Ви виконуєте щодня, тоді Ваші пошуки не були марними!
                                 Ми пропонуємо Вам скористатися існуючими автоматичними кроками, які підходять для будь-якого сайту.
                                 А також надаємо можливість створити власні автокроки. Сподіваємось ASteps допоможе Вам зберегти Ваш час.
                             </div>}
                             indent={7} />
            </Row>
            <Row horizontalAlign={'center'} style={{ marginLeft: '5%', width: '90%', flexWrap: 'wrap' }}>
                <div style={{ width: '50%', minWidth: '350px' }}>
                    <Space hSize={'10px'} />
                    <AddonLink />
                    {this.props.isMobile &&
                        <>
                            <Space hSize={'10px'} />
                            <KiwiBrowserLink />
                        </>
                    }
                </div>
                <div style={{width: '50%', minWidth: '300px'}}>
                    <Row horizontalAlign={'center'}>
                        <div>
                            <Space hSize={'10px'} />
                            <Info title={'Користувач може:'} lines={[
                                'Запустити будь-який автоматичний крок',
                                'Створити власні кроки для будь-якого сайту'
                            ]} />
                            <Info title={'Підтримка браузерів на PC:'} lines={[
                                'Chrome'
                            ]} />
                            <Info title={'Підтримка браузерів на Mobile:'} lines={[
                                '"Safari" for iOS', '"Kiwi Browser" for Android'
                            ]} />
                        </div>
                    </Row>
                </div>
            </Row>
            <Space hSize={'10px'} />
            <Row>
                <Description title={'ASteps плагін:'}
                             titleSize={'18px'}
                             descriptionSize={'17px'}
                             descriptionContent={<div>
                                 Цей плагін <b>не збирає</b> жодних даних! Він передає скріпт будь-якуому сайту с діями такими як: натиснути, знайти елемент з текстом, навігація по URL тощо.
                             </div>}
                             indent={7} />
            </Row>
        </div>
    }
}

Home.propTypes = {
}

export default () => {
    const isMobile = Utils.useCheckMobileScreen();

    return <Home isMobile={isMobile} />
}