import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import List from '../../components/List';
import Column from '../../components/Column';
import Space from '../../components/Space';
import Description from '../../components/Description';
import IconButton from '../../components/IconButton';
import AStepsProjectDB from '../../units/db/asteps-project-db';
import Row from '../../components/Row';
import './index.css';

class AutoStepsProjects extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            projects: [],
            activeProject: null
        }
    }

    handleChangeList = (activeProject) => {
        this.setState({ activeProject });
    }

    handleNavToNewProjectPage = () => {
        this.props.navigate('/auto-steps/projects/new');
    }

    handleNavTo = () => {
        const { activeProject } = this.state;

        if (activeProject) {
            this.props.navigate('/auto-steps/projects/' + activeProject.projectPath);
        } else {
            console.log('No active project');
        }
    }

    componentDidMount() {
        AStepsProjectDB.getAll().then((projects) => {
            this.setState({ projects });
        });
    }

    render() {
        const { projects } = this.state;

        return <>
            <Header indent={5} title={'ASteps проєкти'}>
                <Space hSize={'7px'} />
{/*                 <IconButton style={{ width: '25px', height: '25px' }} */}
{/*                             iconName={'MdAddCircleOutline'} */}
{/*                             tooltipAlign={'left'} */}
{/*                             buttonStyle={'add'} */}
{/*                             tooltipInfo={'Створити проєкт'} */}
{/*                             onClick={this.handleNavToNewProjectPage} /> */}
            </Header>
            <Row className={'auto-list-container'}>
                <Column size={6}>
                    <List items={projects}
                          onChange={this.handleChangeList}/>
                </Column>
                {projects.length !== 0 &&
                    <Column size={1} style={{ alignItems: 'center' }}>
                        <Space hSize={'5px'} />
                        <IconButton iconName={'MdArrowForwardIos'}
                                    tooltipAlign={'left'}
                                    tooltipInfo={'Відкрити проєкт'}
                                    onClick={this.handleNavTo} />
                    </Column>
                }
                <Column size={projects.length !== 0 ? 5 : 6}>
                    <Description title={this.state.activeProject?.title}
                                 description={this.state.activeProject?.description} />
                </Column>
            </Row>
        </>
    }
}

export default () => {
    const navigate = useNavigate();
    return (
        <AutoStepsProjects navigate={navigate} />
    )
};