import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

class Link extends React.Component {

    render() {

        return <a className={'link-container'} target='_blank' href={this.props.url}>
            {this.props.title}
        </a>
    }
}

Link.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string
}

Link.defaultProps = {
    title: null,
    url: null
}

export default Link;