import React from 'react';

class NoPage extends React.Component {

    render() {

        return <div>
            404 Page
        </div>
    }
}
export default NoPage;