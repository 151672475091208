import React from 'react';
import Select from "../../../components/Select";

class FieldSelect extends React.Component {

    static options = [
        {
            value: 'none',
            title: 'Нічого не вибрано'
        }
    ]

    constructor(props) {
        super(props);

        this.state = {
            options: [],
            activeValue: 'none',
            result: null
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const options = [...FieldSelect.options];
        let activeValue = prevState.activeValue || 'none',
            result = prevState.result || null;

        nextProps.fields?.filter((field) => {
            const { type, key, value } = field.options;

            if (type === 'array') {
                if (key && value) {
                    options.push({ value: field.id, title: key });
                }
            }
        });

        if (options.find((option) => option.value === activeValue)) {
            let nextResult = nextProps.fields?.find((field) => field.id === activeValue)?.options.value;
            if (result !== nextResult) {
                result = nextResult;
            }
        } else {
            activeValue = 'none';
            result = null;
        }

        return {
            options,
            activeValue,
            result
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const nextResult = this.state.result;
        const prevResult = prevState.result;

        if (nextResult !== prevResult) {
            this.props?.onChange(nextResult);
        }
    }

    getOption(fields, activeValue) {
        return fields?.find((field) => field.id === activeValue)?.options;
    }

    handleChange = (activeValue) => {
        const result = (activeValue !== 'none')
            ? this.getOption(this.props.fields, activeValue)?.value || null
            : null;

        this.setState({ activeValue, result },
            () => this.props?.onChange(this.state.result));
    }

    render() {
        return <Select value={this.state.activeValue}
                       options={this.state.options}
                       onChange={this.handleChange} />
    }
}

export default FieldSelect;