import React, { useEffect, useState } from 'react';

class Utils {

    static useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }

    static equalArrays(a1, a2) {

        if (!a1 || !a2) {
            return false;
        }

        if (a1.length !== a2.length) {
            return false;
        }

        for (let i = 0; i < a1.length; i++) {

            if (!Utils.equals(a1[i], a2[i])) {
                return false;
            }
        }

        return true;
    }

    static equalObjects(o1, o2) {
        const k1 = Object.keys(o1);
        const k2 = Object.keys(o2);

        if (k1.length !== k2.length) {
            return false;
        }

        for (let k of k1) {

            if (!Utils.equals(o1[k], o2[k])) {
                return false;
            }
        }

        return true;
    }

    static equals(v1, v2) {
        const areArrays = Array.isArray(v1) && Array.isArray(v2);
        const areObjects = Utils.isObject(v1) && Utils.isObject(v2);

        if (areArrays && !Utils.equalArrays(v1, v2)
            || !areArrays && areObjects && !Utils.equalObjects(v1, v2)
            || !areArrays && !areObjects && v1 !== v2) {
            return false;
        }
    }

    static isObject(v) {
        if (!v) { return false; }
        return typeof v === 'function' || typeof v === 'object';
    }

}

export default Utils;