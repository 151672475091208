import React from 'react';
import PropTypes from 'prop-types';
import Row from '../../Row';
import Icon from '../../Icon';
import './index.css';

class InfoLine extends React.Component {

    render() {

        return <Row className={'info-line'} verticalAlign={'center'} >
            <Icon style={{ color: 'green' }} name={'MdCheck'} />
            <span>{this.props.text}</span>
        </Row>
    }
}

InfoLine.propTypes = {
    text: PropTypes.string.isRequired
}

export default InfoLine;