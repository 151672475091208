import React from 'react';
import Select from "../Select";
import PropTypes from "prop-types";
import ActionSelect from "../ActionSelect";

class AttributeSelect extends React.Component {

    options = [
        {
            value: 'class',
            title: 'CLASS'
        },
        {
            value: 'classes',
            title: 'CLASSES'
        },
        {
            value: 'id',
            title: 'ID'
        },
        {
            value: 'xpath',
            title: 'XPATH'
        }
    ]

    constructor(props) {
        super(props);

        this.state = {
            attribute: this.validateAttribute(props.attribute)
        }
    }

    validateAttribute(attribute) {

        for (let option of this.options) {
            if (option.value === attribute) {
                return attribute;
            }
        }

        console.warn(`AttributeSelect supports ${this.options.map((option) => option.value).toString()}`);
        return null;
    }

    handleChange = (value) => {
        this.setState({ attribute: value });
        this.props.onChange?.(value);
    }

    render() {
        return <Select value={this.state.attribute}
                       editable={this.props.editable}
                       label={'Властивість'}
                       options={this.options}
                       onChange={this.handleChange} />
    }
}

AttributeSelect.propTypes = {
    editable: PropTypes.bool
}

AttributeSelect.defaultProps = {
    editable: true
}

export default AttributeSelect;