import React from 'react';
import Select from "../Select";
import PropTypes from "prop-types";
import Step from "../../shared/AutoStepsEditor/Group/Step";

class ActionSelect extends React.Component {

    options = [
        {
            value: 'findTextContent',
            title: 'Пошук по тексту'
        },
        {
            value: 'findElement',
            title: 'Пошук елемента'
        },
        {
            value: 'click',
            title: 'Натиснути'
        },
        {
            value: 'navigateTo',
            title: 'Перейти'
        }
    ]

    constructor(props) {
        super(props);

        this.state = {
            action: this.validateAction(props.action)
        }
    }

    validateAction(action) {

        for (let option of this.options) {
            if (option.value === action) {
                return action;
            }
        }

        console.warn('Not found action: ' + action);
        console.warn(`ActionSelect supports ${this.options.map((option) => option.value).toString()}`);
        return null;
    }

    handleChange = (value) => {
        this.setState({ action: value });
        this.props.onChange?.(value);
    }

    render() {
        return <Select value={this.state.action}
                       editable={this.props.editable}
                       label={'Дія'}
                       options={this.options}
                       onChange={this.handleChange} />
    }
}

ActionSelect.propTypes = {
    editable: PropTypes.bool
}

ActionSelect.defaultProps = {
    editable: true
}

export default ActionSelect;