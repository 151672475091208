import React from 'react';
import PropTypes from 'prop-types';
import Content from '../../components/Content';
import IconButton from '../../components/IconButton';
import Group from './Group';
import './index.css';

class AutoStepsEditor extends React.Component {

    handleAddGroup = (id = -1) => {
        const group = Group.createGroup();

        this.handleChange('group', 'add', {
            id, group
        });
    }

    handleChange = (item, action, result) => {
        this.props.onChange?.(item, action, result);
    }

    get headerElement() {

        if (!this.props.editable) {
            return null;
        }

        return <IconButton iconName={'MdOutlineAdd'}
                           buttonStyle={'add'}
                           tooltipAlign={'left'}
                           tooltipInfo={'Додати групу'}
                           onClick={this.handleAddGroup}/>
    }

    get stepsContainer() {
        const { groups } = this.props;

        if (groups.length === 0) { return null }

        return groups.map((group, index) =>
            <div key={group.id} className={'automation-editor-content'}>
                <Group editable={this.props.editable}
                       index={index}
                       group={group}
                       fields={this.props.fields}
                       onChange={this.props.onChange}/>
            </div>)
    }

    render() {

        return <div className={'automation-editor-container'}>
            <Content title={'Редартор автоматичних кроків'}
                     headerElement={this.headerElement}>
                {this.stepsContainer}
            </Content>
        </div>
    }
}

AutoStepsEditor.propTypes = {
    groups: PropTypes.array.isRequired,
    fields: PropTypes.array.isRequired,
    editable: PropTypes.bool,
    onChange: PropTypes.func
}

AutoStepsEditor.defaultProps = {
    editable: true
}

export default AutoStepsEditor;