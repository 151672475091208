export default class Logger {
	
	constructor(className) {
		this._className = className;
	}
	
	#log(level, message) {
		let msg = this._className + ' > ' + message;
		msg = level.toUpperCase() + ': ' + msg;
		console.log(msg);
	}
	
	info(message) {
		this.#log('info', message);
	}
	
	warn(message) {
		this.#log('warn', message);
	}
	
	error(message) {
		this.#log('error', message);
	}
}