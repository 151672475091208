export default class UIFieldValidation {

    validateArrayData(options) {
        let message = '';

        if (!options.title) {
            message = '- Немає назви';
        }

        if (!options.key) {
            message += message.length !== 0 ? '\n' : '';
            message += '- Немає ключа';
        }

        return message;
    }

}