import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import Icon from "../Icon";
import Row from "../Row";

class Input extends React.Component {

    static inputContent = {
        'primitive': 'primitive-input',
        'error': 'error-input',
        'disabled': 'disabled-input'
    }

    static inputLabel = {
        'primitive': 'primitive-label',
        'error': 'error-label',
        'disabled': 'disabled-label'
    }

    constructor(props) {
        super(props);

        this.state = {
            focused: false,
            errorMessages: '',
            dirty: false
        }
    }

    static getDerivedStateFromProps(props, state) {

        // if (!props.dirty && !state.dirty) {
        //     return {
        //         focused: state.focused,
        //         errorMessages: ''
        //     };
        // }

        let errorMessages = '';

        if (props.required && !props.value) {
            errorMessages += '- Порожнє поле';
        }

        if (props.justLatin && props.value.match(/[^a-z\-_]/i)) {
            errorMessages += '- Введіть тільки латинські символи та - _';
        }

        if (props.type === 'number' && isNaN(+props.value)) {
            errorMessages += '- Введіть тільки числа';
        }

        if (props.maxSize !== 0 && props.maxSize < props.value.length) {
            if (errorMessages) {  errorMessages += '\n'; }
            errorMessages += '0 Багато символів';
        }

        if (props.unique.length !== 0 && props.unique.includes(props.value)) {
            if (errorMessages) {  errorMessages += '\n'; }
            errorMessages += '- Вже є таке значеня';
        }

        if (state.errorMessages !== errorMessages) {
            props.onValidate?.(errorMessages);
        }

        return {
            focused: state.focused,
            errorMessages
        };
    }

    handelChange = (event) => {
        let { value } = event.target;

        if (this.props.maxSize !== 0 && value.length > this.props.maxSize) {
            value = value.substring(0, this.props.maxSize);
        }

        this.setState({ dirty: true, value });
        this.props.onChange?.(value, this.props.args);
        event.preventDefault();
    }

    handleIgnore = (event) => {

        if (this.props.justLatin && event.key.match(/[^a-z\-_]/i)) {
            event.preventDefault();
        }

        if (this.props.type === 'number'
            && ['e', 'E', '+', '-', '.'].includes(event.key)) {
            event.preventDefault();
        }
    }

    handleFocus = (event) => this.handleUpdate(event, true);

    handleBlur = (event) => this.handleUpdate(event, false);

    handleUpdate = (event, focused) => {
        this.setState({ focused });
        event.preventDefault();
    }

    get inputContentStyle() {
        const style = {...this.props.inputStyle};

        if (this.state.focused) {
            style.outline = 'none';
        }

        return style;
    }

    get labelContentStyle() {
        const style = {};

        if (this.props.value) {
            style.top = '0%';
        }

        return style;
    }

    get labelStyle() {
        const style = {...this.props.labelStyle};

        if (this.props.disabled) {
            style.color = '#5d5d5d';
        }

        if (this.props.value) {
            style.fontSize = '11px';
            style.fontWeight = 'bold';
        }

        return style;
    }

    updateInputContent = (errorMessages) => {
        let classNames = 'input-content';

        if (this.props.disabled) {
            classNames += ' ' + Input.inputContent['disabled'];
        } else if ((this.props.dirty || this.state.dirty) && errorMessages) {
            classNames += ' ' + Input.inputContent['error'];
        } else {
            classNames += ' ' + Input.inputContent['primitive'];
        }

        return classNames;
    }

    updateInputLabel = (errorMessages) => {
        let classNames = 'input-label';

        if (this.props.disabled) {
            classNames += ' ' + Input.inputLabel['disabled'];
        } else if ((this.props.dirty || this.state.dirty) && errorMessages) {
            classNames += ' ' + Input.inputLabel['error'];
        } else {
            classNames += ' ' + Input.inputLabel['primitive'];
        }

        return classNames;
    }

    render() {
        const { errorMessages } = this.state;

        return (
            <div style={this.props.containerStyle} className={'input-body'}>
                <Row verticalAlign={'center'}>
                    {(this.props.dirty || this.state.dirty) && errorMessages &&
                        <Icon style={{width: '15px', height: '15px'}}
                              tooltipAlign={this.props.tooltipAlign}
                              className={'icon-error'}
                              name={'MdErrorOutline'}
                              tooltipStyle={'error'}
                              tooltipMessage={errorMessages}/>
                    }
                    <div className={'input-container'}>
                        <input ref={(input) => this._input = input}
                               className={this.updateInputContent(errorMessages)}
                               style={this.inputContentStyle}
                               type={this.props.type || 'text'}
                               disabled={this.props.disabled}
                               value={this.props.value}
                               onChange={this.handelChange}
                               onKeyDown={this.handleIgnore}
                               onFocus={this.handleFocus}
                               onBlur={this.handleBlur}/>
                        <div className={'input-label-content'}
                             style={this.labelContentStyle}
                             onClick={() => this._input.focus()}>
                            <div className={this.updateInputLabel(errorMessages)}
                                 style={this.labelStyle}>
                                {this.props.required &&
                                    <span className={'required-input'}>* </span>
                                }
                                {this.props.label}
                            </div>
                        </div>
                        {this.props.maxSize !== 0 &&
                            <div className={'input-counter-content'}>
                                <span>{this.props.value.length} / {this.props.maxSize}</span>
                            </div>
                        }
                    </div>
                </Row>
            </div>
        );
    }
}

Input.propTypes = {
    type: PropTypes.string,
    dirty: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    justLatin: PropTypes.bool,
    maxSize: PropTypes.number,
    value: PropTypes.string,
    label: PropTypes.string,
    containerStyle: PropTypes.object,
    tooltipAlign: PropTypes.string,
    labelStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    onChange: PropTypes.func,
    onValidate: PropTypes.func,
    unique: PropTypes.array
}

Input.defaultProps = {
    justLatin: false,
    maxSize: 0,
    unique: []
}

export default Input;