import React from 'react';
import PropTypes from 'prop-types';
import Field from './Field';
import IconButton from '../../components/IconButton';
import './index.css';
import Content from "../../components/Content";

class UserInterfaceEditor extends React.Component {

    get indentStyle() {
        const { indent } = this.props;
        const style = {};

        style.width = (100 - indent * 2) + '%';
        style.marginLeft = indent + '%';

        return style;
    }

    handleAddField = () => {

        if (this.props.hasError) {
            this.props.onDirty();
            return;
        }

        const field = Field.createField();
        this.props.onChange?.('field', 'add', { id: -1, field });
    }

    get interfaceEditorHeader() {
        return <IconButton iconName={'MdOutlineAdd'}
                           disabled={this.props.dirty && this.props.hasError}
                           buttonStyle={'add'}
                           tooltipAlign={'left'}
                           tooltipInfo={'Додати поле'}
                           onClick={this.handleAddField} />
    }

    get interfaceEditorContainer() {
        const { fields } = this.props;

        if (fields.length === 0) { return null }

        return (<div className={'field-editor-content'} >
            {this.props.fields.map((field, index) =>
                <Field key={field.id}
                       index={index}
                       hasError={this.props.hasError}
                       uiErrors={this.props.uiErrors}
                       dirty={this.props.dirty}
                       field={field}
                       behaviorAs={this.props.behaviorAs}
                       onChange={this.props.onChange}
                       onValidate={this.props.onValidate}
                       onDirty={this.props.onDirty} />
            )}
        </div>)
    }

    render() {

        return <div style={this.indentStyle}>
            <Content title={'Редактор інтерфесу'}
                     headerElement={this.interfaceEditorHeader}>
                {this.interfaceEditorContainer}
            </Content>
        </div>
    }
}

UserInterfaceEditor.propTypes = {
    hasError: PropTypes.bool,
    uiErrors: PropTypes.object,
    dirty: PropTypes.bool,
    indent: PropTypes.number,
    behaviorAs: PropTypes.string,
    onChange: PropTypes.func,
    onValidate: PropTypes.func,
    onDirty: PropTypes.func
}

UserInterfaceEditor.defaultProps = {
    dirty: false,
    uiErrors: {},
    indent: 0,
    behaviorAs: 'editor'
}

export default UserInterfaceEditor;