import React from 'react';
import PropTypes from 'prop-types';
import AppComponent from '../AppComponent';
import './index.css';

class Select extends AppComponent {

    static noOptions = [
        {
            value: 'noOptions',
            title: 'Немає опцій'
        }
    ];

    constructor(props) {
        super('Select', props);

        this.state = {
            focused: false
        }
    }

    handleFocus = (event) => this.handleUpdate(event, true);

    handleBlur = (event) => this.handleUpdate(event, false);

    handleUpdate = (event, focused) => {
        this.setState({ focused });
        event.preventDefault();
    }

    handelChangeValue = (event) => {

        if (!this.props.editable) {
            return;
        }

        this.props.onChange?.(event.target.value, this.props.args);
        event.preventDefault();
    }

    get selectContentStyle() {
        const style = {};

        if (this.props.disabled) {
            style.borderColor = '#9f9f9f';
            style.color = '#4d4d4d';
        } else if (this.state.focused) {
            style.outline = 'none';
            // style.borderColor = '#c2c2ff';
            // style.color = '#3f3f8c';
        }

        return style;
    }

    get labelStyle() {
        const style = {};

        if (this.props.disabled) {
            style.color = '#5d5d5d';
        }

        return style;
    }

    render() {
        let value = this.props.value || this.props.defaultValue;
        let options = (this.props.options?.length) ? this.props.options : Select.noOptions;

        return <div style={this.props.containerStyle} className={'select-container'}>
            <select ref={(select) => this._select = select}
                    className={'select-content'}
                    style={this.selectContentStyle}
                    value={value}
                    onChange={this.handelChangeValue}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}>
                {options.map((option) =>
                    <option className={'option-content'}
                            key={option.value}
                            value={option.value}>{option.title}</option>)}
            </select>
            {this.props.label &&
                <div className={'select-label-content'}>
                    <div className={'select-label'}
                         style={this.labelStyle}>{this.props.label}</div>
                </div>
            }
        </div>
    }
}

Select.propTypes = {
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
    containerStyle: PropTypes.object
}

Select.defaultProps = {
    editable: true,
    options: [],
    containerStyle: {}
}

export default Select;