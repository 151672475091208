import React from 'react';
import Select from "../../../../components/Select";
import PropTypes from "prop-types";

class TypeSelect extends React.Component {

    options = [
        {
            value: 'input',
            title: 'Введення'
        },
        {
            value: 'array',
            title: 'Масив'
        },
        {
            value: 'dropdown',
            title: 'Випадаюче меню'
        }
    ]

    constructor(props) {
        super(props);

        this.state = {
            type: this.validateType(props.type)
        }
    }

    validateType(type) {

        for (let option of this.options) {
            if (option.value === type) {
                return type;
            }
        }

        console.warn('Not found action:' + type);
        console.warn(`ActionSelect supports ${this.options.map((option) => option.value).toString()}`);
        return null;
    }

    handleChange = (type) => {
        this.setState({ type },
            () => this.props.onChange?.(this.state.type));
    }

    render() {
        return <Select value={this.state.type}
                       options={this.options}
                       editable={this.props.editable}
                       onChange={this.handleChange} />
    }
}

TypeSelect.propTypes = {
    editable: PropTypes.bool
}

TypeSelect.defaultProps = {
    editable: true
}

export default TypeSelect;