import React from 'react';
import Row from '../../../components/Row';
import { ReactComponent as ActiveLogo } from '../../../asserts/active-logo.svg';
import './index.css';
import RequestCommand from "../../../units/command/request-command";
import CommandType from "../../../units/command/command-type";


class AddonLink extends React.Component {

    static CHROME_ADDON_URL = 'https://chromewebstore.google.com/detail/asteps/fcamdjkepffocnmlpdjjpelbjakngnah';

    constructor(props) {
        super(props);

        this.state = {
            checkedAddon: false,
            errorReason: null,
            numberOfConnectionAttempts: 0
        }
    }

    handleSuccess = () => {
        this.setState({ checkedAddon: true, errorReason: null });
    }

    handleFailure = (errorReason) => {
        if (!errorReason) {
            this.setState({ checkedAddon: true,  errorReason: 'unexpected-error' });
            return;
        }

        if (this.state.numberOfConnectionAttempts >= 4) {
            this.setState({ checkedAddon: true,  errorReason });
        } else {
            const numberOfConnectionAttempts = this.state.numberOfConnectionAttempts + 1;
            this.setState({ numberOfConnectionAttempts },
                this.handleRefresh);
        }
    }

    handleRefresh = () => {
        RequestCommand.request(CommandType.CHECK_ADDON, {})
            .then(this.handleSuccess)
            .catch(this.handleFailure);
    }

    componentDidMount() {
        this.handleRefresh();
    }

    render() {

        return <a className={'addon-link-container'} target='_blank' href={AddonLink.CHROME_ADDON_URL}>
            <Row className={'addon-logo-content active-addon-logo'}
                        horizontalAlign={'center'}
                        verticalAlign={'center'}>
                <div style={{ padding: '0 10px 0 10px' }}>
                    <Row horizontalAlign={'center'}
                         verticalAlign={'center'}>
                        <span><b>ASteps</b> add-on:</span>
                        {!this.state.checkedAddon &&
                            <i className={'disabled-addons-status'}> Перевіряється</i>
                        }
                        {this.state.checkedAddon && !this.state.errorReason &&
                            <i className={'active-addons-status'}> Активний</i>
                        }
                        {this.state.checkedAddon && this.state.errorReason &&
                            <i className={'inactive-addons-status'}> Неактивний</i>
                        }
                    </Row>
                    <Row horizontalAlign={'center'} verticalAlign={'center'}>
                        <ActiveLogo className={'addon-logo'} />
                    </Row>
                    <Row horizontalAlign={'center'} verticalAlign={'center'}>
                        <span className={'addon-description'}>Плагін автоматичних кроків по сторінкам</span>
                    </Row>
                    {this.state.checkedAddon && this.state.errorReason &&
                        <>
                            <Row horizontalAlign={'center'} verticalAlign={'center'}>
                                <i className={'install-addon-description'}>Встановіть чи увімкніть add-on</i>
                            </Row>
                            <Row horizontalAlign={'center'} verticalAlign={'center'}>
                                <i className={'install-addon-description'}>Перезавантажте сторінку після активації</i>
                            </Row>
                        </>
                    }
                </div>
            </Row>
        </a>
    }
}

export default AddonLink;