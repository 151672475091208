import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

class IconButton extends React.Component {

    static buttonStyle = {
        'disabled': 'icon-disabled',
        'error': 'icon-error',
        'primitive': 'icon-primitive',
        'add': 'icon-add',
        'remove': 'icon-remove'
    }

    handleClick = () => {
        if (!this.props.disabled) {
            this.props.onClick?.(this.props.args);
        }
    }

    get tooltipStyle() {
        if (this.props.tooltipError) {
            return 'error';
        }

        return 'primitive';
    }

    get tooltipMessage() {
        if (this.props.tooltipError) {
            return this.props.tooltipError;
        }

        return this.props.tooltipInfo;
    }

    get iconStyle() {

        if (this.props.disabled) {
            return IconButton.buttonStyle['disabled'];
        }

        if (this.props.tooltipError) {
            return IconButton.buttonStyle['error'];
        }

        const buttonStyle = IconButton.buttonStyle[this.props.buttonStyle];
        if (buttonStyle) {
            return buttonStyle;
        }


        return IconButton.buttonStyle['primitive'];
    }

    render() {

        return <Icon style={this.props.style}
                     className={this.iconStyle}
                     disabled={this.props.disabled}
                     name={this.props.iconName}
                     tooltipStyle={this.tooltipStyle}
                     tooltipMessage={this.props.disabled ? null : this.tooltipMessage}
                     tooltipAlign={this.props.tooltipAlign}
                     behaviorAs={'button'}
                     onClick={this.handleClick} />
    }
}

IconButton.propTypes = {
    disabled: PropTypes.bool,
    iconName: PropTypes.string.isRequired,
    tooltipError: PropTypes.string,
    tooltipInfo: PropTypes.string,
    tooltipAlign: PropTypes.string,
    buttonStyle: PropTypes.string,
    args: PropTypes.object,
    style: PropTypes.object,
}

Icon.defaultProps = {
    tooltipStyle: 'primitive'
}

export default IconButton;