import React from 'react';
import { useNavigate } from 'react-router-dom';
import Column from '../../../components/Column';
import Row from '../../../components/Row';
import PetitionSorter from '../../../units/sorter/petition-sorter';
import Header from "../../../components/Header";
import Space from "../../../components/Space";
import Button from "../../../components/Button";
import './index.css';
import IconButton from "../../../components/IconButton";
import PropTypes from "prop-types";

class PetitionTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            notPassed: Object.keys(PetitionSorter.notPassed),
            passed: Object.keys(PetitionSorter.passed),
            done: Object.keys(PetitionSorter.done),
            skipped: Object.keys(PetitionSorter.skipped),
        }

        PetitionSorter.addUpdatePetitionIdStatus((notPassed, passed, done, skipped) => {
            this.setState({
                notPassed: Object.keys(notPassed),
                passed: Object.keys(passed),
                done: Object.keys(done),
                skipped: Object.keys(skipped)
            });
        })
    }

    handlerNavToPetitionNumbers = () => {
        this.props.navigate('/auto-steps/ua-petitions/numbers');
    }

    handleChangeFromPassedToNotPassed = () => {

        if (this.state.passed.length === 0) {
            return;
        }

        if (window.confirm("Перенесе номера петиції з графу 'Підписані' в 'Не підписані'. Продовжити?")) {
            PetitionSorter.clear(this.props.category, 'passed');
        }
    }

    render() {

        return <>
            <Space hSize={'10px'} />
            <Header indent={5} titleSize={17} title={'Номера петицій'}>
                {this.props.showNavigatorToPN &&
                    <IconButton iconName={'MdAddCircleOutline'}
                                style={{ width: '25px', height: '25px' }}
                                buttonStyle={'add'}
                                tooltipAlign={'left'}
                                tooltipInfo={'Додати номер'}
                                onClick={this.handlerNavToPetitionNumbers} />
                }
            </Header>
            <Row className={'table-container'}>
            <Column className={'not-pass-column'} size={6}>
                <Row className={'table-head-counter'}
                     verticalAlign={'center'}
                     horizontalAlign={'center'} >
                    <span>{this.state.notPassed.length}</span>
                </Row>
                <Row className={'table-head'}
                     verticalAlign={'center'}
                     horizontalAlign={'center'} >
                    <span>Не підписані</span>
                </Row>
                {this.state.notPassed.slice(0, 15).map((petitionId) =>
                    <Row key={petitionId}
                         className={'table-row'}
                         verticalAlign={'center'}
                         horizontalAlign={'center'} >
                        <span>{petitionId}</span>
                    </Row>
                )}
                {this.state.notPassed.length > 15 &&
                    <Row className={'table-row'}
                        verticalAlign={'center'}
                        horizontalAlign={'center'} >
                        <span>...</span>
                    </Row>
                }
            </Column>
            <Column className={'pass-column'} size={6}>
                <Row className={'table-head-counter'}
                     verticalAlign={'center'}
                     horizontalAlign={'center'} >
                    <span>{this.state.passed.length}</span>
                </Row>
                <Row className={'table-head head-list'}
                     verticalAlign={'center'}
                     horizontalAlign={'center'}>
                    <div style={{ marginRight: '5px' }}>
                        <Space hSize={'5px'}/>
                        <IconButton iconName={'MdOutlineRefresh'}
                                    disabled={this.state.passed.length === 0}
                                    onClick={this.handleChangeFromPassedToNotPassed} />
                    </div>
                    <span>Підписані</span>
                </Row>
                {this.state.passed.slice(0, 15).map((petitionId) =>
                    <Row key={petitionId}
                         className={'table-row'}
                         verticalAlign={'center'}
                         horizontalAlign={'center'} >
                        <span>{petitionId}</span>
                    </Row>
                )}
                {this.state.passed.length > 15 &&
                    <Row className={'table-row'}
                        verticalAlign={'center'}
                        horizontalAlign={'center'} >
                        <span>...</span>
                    </Row>
                }
            </Column>
            </Row>
        </>
    }
}

PetitionTable.propTypes = {
    category: PropTypes.string,
    showNavigatorToPN: PropTypes.bool
}

export default ({ category, showNavigatorToPN }) => {
    const navigate = useNavigate();

    return (
        <PetitionTable category={category}
                       showNavigatorToPN={showNavigatorToPN}
                       navigate={navigate} />
    )
}