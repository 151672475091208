import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

class Description extends React.Component {

    get indentStyle() {
        const { indent } = this.props;
        const style = { };

        style.width = (100 - indent * 2) + '%';
        style.marginLeft = indent + '%';

        return style;
    }

    get titleSize() {
        return { fontSize: this.props.titleSize }
    }

    get descriptionSize() {
        return { fontSize: this.props.descriptionSize }
    }

    render() {

        if (!this.props.description && !this.props.descriptionContent) {
            return <div style={{...this.indentStyle, ...this.props.style}} className={'empty-description'}>
                <h2>Немає опису</h2>
            </div>
        }

        if (this.props.description) {
            return <div style={{...this.indentStyle, ...this.props.style}} className={'description-container'}>
                {this.props.title && <div style={this.titleSize} className={'title-description'}>
                    <span>{this.props.title}</span>
                </div>}
                <div style={this.descriptionSize} className={'text-description'}>
                    {this.props.description}
                </div>
            </div>
        }

        return <div style={{...this.indentStyle, ...this.props.style}} className={'description-container'}>
            {this.props.title && <div style={this.titleSize} className={'title-description'}>
                <span>{this.props.title}</span>
            </div>}
            <div style={this.descriptionSize} className={'text-description'}>
                {this.props.descriptionContent}
            </div>
        </div>
    }
}

Description.propTypes = {
    style: PropTypes.object,
    titleSize: PropTypes.string,
    title: PropTypes.string,
    descriptionSize: PropTypes.string,
    description: PropTypes.string,
    descriptionContent: PropTypes.element,
    indent: PropTypes.number
}

Description.defaultProps = {
    titleSize: '15px',
    title: null,
    descriptionSize: '14px',
    description: null,
    descriptionContent: null,
    indent: 0
}

export default Description;