import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem';
import './index.css';

class List extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeId: null
        }
    }

    static getDerivedStateFromProps(props, state) {
        let activeId = state.activeId;

        if (!activeId && props.items.length !== 0) {
            const activeItem = props.items[0];
            activeId = activeItem.id;
            props.onChange?.(activeItem);
        }

        return { activeId };
    }

    handleClick = (activeId) => {
        this.setState({ activeId });
        this.props.onChange?.(this.props.items.find((item) => item.id === activeId));
    }

    isActive = (item) => {
        const activeId = this.state.activeId
            ? this.state.activeId : this.props.items[0].id;

        return activeId === item.id;
    }

    render() {
        const { items } = this.props;

        if (items.length === 0) {
            return <div className={'empty-list-item'}>
                <h2>No items</h2>
            </div>
        }

        return <>
            {items.map((item) => (
                <ListItem key={item.id}
                          id={item.id}
                          active={this.isActive(item)}
                          title={item.title}
                          onClick={this.handleClick}  />
            ))}
        </>
    }
}

List.propTypes = {
    items: PropTypes.array,
    onChange: PropTypes.func
}

List.defaultProps = {
    items: []
}

export default List;