import ResponseCommand from './response-command';
import { v4 as uuidv4 } from 'uuid';

export default class RequestCommand {

    static autoStepsListener = null;

    static body = {
        method: "request"
    }

    static finish() {
        if (RequestCommand.autoStepsListener) {
            ResponseCommand.off(RequestCommand.autoStepsListener);
            RequestCommand.autoStepsListener = null;
        }
    }

    static start(body, success, failure) {

        if (RequestCommand.autoStepsListener) {
            console.log('Unable to start auto steps, because it run');
            return;
        }

        let id = uuidv4();

        RequestCommand.autoStepsListener = ResponseCommand.on((result) => {

            if (id !== result.id) {
                return;
            }

            if (result.status === 'success') {

                if (result.body.progress === 'finished') {
                    RequestCommand.finish();
                }

                success(result.body);
            } else if (result.status === 'failure') {
                failure(result.body.reason);
            }
        });

        let req = {
            id,
            method: 'request',
            command: 'start',
            ...body
        };

        console.log('Send start request: ')
        console.log(req)

        window.postMessage(req);
    }

    static request(command, body) {

        return new Promise((resolve, reject) => {
            let id = uuidv4();
            let timer = null;

            let listener = ResponseCommand.on((result) => {

                if (id !== result.id) {
                    return;
                }

                clearTimeout(timer);

                if (result.status === 'success') {
                    resolve(result.body);
                } else if (result.status === 'failure') {
                    reject(result.body.reason);
                }
            });

            // Видаляє слухача та передає повідомлення що закінчився час
            timer = setTimeout(() => {
                ResponseCommand.off(listener);
                reject('timeout');
            }, 1000);

            // Відправляє запрос
            if (body) {
                window.postMessage({ id, ...RequestCommand.body, command, ...body });
            } else {
                window.postMessage({ id, ...RequestCommand.body, command });
            }
        });
    }

    static on(callback) {

        window.addEventListener('message', (e) => {

            if (e.data?.method !== 'request') {
                return;
            }

            let errorReason = callback(e.data);

            if (errorReason) {
                ResponseCommand.failure(e.data.id, errorReason);
                return;
            }

            ResponseCommand.success(e.data.id, {});
        }, false);
    }
}