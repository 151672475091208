export default class ResponseCommand {

    static body = {
        method: "response"
    }

    static success(id, body) {
        ResponseCommand.response('success', id, body);
    }

    static failure(id, reason) {
        ResponseCommand.response('failure', id, { reason });
    }

    static response(status, id, body) {
        window.postMessage({ ...ResponseCommand.body, status, id, body });
    }

    static on(callback) {
        const listener = (e) => {

            if (e.data?.method !== 'response') {
                return;
            }

            callback(e.data);
        }

        window.addEventListener('message', listener, false);
        return listener;
    }

    static off(listener) {
        window.removeEventListener('message', listener);
    }
}