export default class FirebaseOptions {

    static aStepsConfig = {
        apiKey: "AIzaSyBm5xPGBYBFCMRfjFNN_oPKI1hBavUoUmo",
        authDomain: "asteps-list.firebaseapp.com",
        databaseURL: "https://asteps-list-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "asteps-list",
        storageBucket: "asteps-list.appspot.com",
        messagingSenderId: "388456536145",
        appId: "1:388456536145:web:75393da1427bc2a4d58070",
        measurementId: "G-8VT0FCXWLL"
    };

    static petitionConfig = {
        apiKey: "AIzaSyBL3coPtjyHtXaSqM0kRO6NkTaSs0AAi4o",
        authDomain: "pretisionnumbersua.firebaseapp.com",
        databaseURL: "https://pretisionnumbersua-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "pretisionnumbersua",
        storageBucket: "pretisionnumbersua.appspot.com",
        messagingSenderId: "76217755382",
        appId: "1:76217755382:web:f92497867de6dd93a16ed7",
        measurementId: "G-40J494VE6E"
    };

}